import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/all.module.css';
import logo from '../assests/images/cardlogo.png'
import Walletmodal from '../modals/walletmodal';
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp, FaAngleLeft, FaMinus, FaPlus, FaRegQuestionCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins } from "react-icons/fa";

import { useDispatch, useSelector } from 'react-redux';
import pools_icon from '../assests/images/pools_icon.png';

import ReactDatatable from '@ashvin27/react-datatable';



import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';
import { useNavigate } from 'react-router-dom';

//modals
import ComingSoonmodal from '../modals/comingSoonmodal';
import Boostmodal from '../modals/boostmodal';
import Unboostmodal from '../modals/unboostmodal';
import Addsuccessmodal from '../modals/addsuccessmodal';

//lib
import { isEmpty } from '../lib/isEmpty';
import fileObjectUrl from '../lib/img'
import { roundToSignificant } from '../hooks/useCommon';




export default function Yieldlist(props) {
    const navigate = useNavigate();

    const [boostmodal, setBoostmodal] = useState(false);
    const [unboostmodal, setUnboostmodal] = useState(false);
    const [modalsucess, setModalsucess] = useState(false);
    const [modalcoming, setmodalcoming] = useState(false);

    const { positionrecord, positionList } = useSelector((state) => (state.position))
    const { nftpooldata } = useSelector((state) => (state.nftpool))
    const { filter } = props

    console.log(positionList, 'Yieldlist')
    const [viewrecord, setViewrecord] = useState({})
    //   const [isbeared, setIsbeared] = useState(false);
    //   const [islocked, setIslocked] = useState(false);
    //   const [isboosted, setIsboosted] = useState(false);

    const [title, settitle] = useState('')
    const [successLoader, setsuccessLoader] = useState(false)
    const [loaderText, setloaderText] = useState('')
    const [successText, setsuccessText] = useState('')
    const [errorText, seterrorText] = useState('')
    const [Trxn, setTrxn] = useState('')

    const [records, setRecords] = useState([])
    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "lpsymbol",
            text: "Token",
            className: "token",
            align: "left",
            sortable: true,
            cell: record => {
                let tokenA = record.token0
                let tokenB = record.token1
                return (
                    <>
                        <div className={`${cascading.imgsec} ${cascading.cursor_pointer_column}`}>
                            <div className={`${cascading.leftimgsec}`}>
                                <img src={isEmpty(tokenA) ? coinlogo : fileObjectUrl(tokenA.image)} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                <img src={isEmpty(tokenB) ? coinlogo : fileObjectUrl(tokenB.image)} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                            </div>
                            <div>
                                <p className={`${cascading.coinname} mb-0`}>{record.lpsymbol}</p>
                                <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{record.tokenId}</p>
                            </div>
                        </div>
                    </>
                );
            }

        },
        {
            key: "amount",
            text: "Amount",
            className: "amount",
            align: "left",
            cell: record => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{roundToSignificant(record.amount, 6)} {record.lpsymbol}</p>
                        </div>
                    </>
                );

            }

        },
        {
            key: "settings",
            text: "Settings",
            className: "settings",
            align: "left",
            sortable: true,
            cell: records => {
                console.log(records, "recordes")
                let islocked = records.lockDuration > 0 ? true : false
                let isboosted = records.boostPoints > 0 ? true : false
                let poolData = nftpooldata.find((val) => (val.poolAddress == records.poolAddress))
                let isbeared = parseFloat(poolData.allocPoint) > 0 ? true : false
                return (
                    <div className='d-flex align-items-center justify-content-start'>
                        <div className='pe-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Yield bearing</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={isbeared ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaCoins /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Lock/Unlock position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={islocked ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaLock /></Button>
                            </OverlayTrigger>
                        </div>

                        <div className='px-2'>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-0'>Boost/Unboost position</p>

                                    </Tooltip>
                                }
                            >
                                <Button className={isboosted ? `${cascading.tooltip_button} ${cascading.tooltip_button_active} ms-0` : `${cascading.tooltip_button} ${cascading.tooltip_button_inactive} ms-0`}><FaRocket /></Button>
                            </OverlayTrigger>
                        </div>




                    </div>
                )

            }
        },
        {
            key: "apr",
            text: "APR",
            className: "apr",
            sortable: true,
            width: 80,
            cell: records => {
                let poolInfo = nftpooldata.find((val) => (val.poolAddress.toLowerCase() == records.poolAddress.toLowerCase()))
                return (

                    <div className='d-flex align-items-center'>
                        <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{poolInfo.Apr}%%</p>
                        <div>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-1'>Swap fees APR: {roundToSignificant(poolInfo?.SwapFeeApr, 4)}%</p>
                                        <p className='mb-1'>Farm APR: {roundToSignificant(poolInfo?.FramBaseApr, 4)}%</p>
                                        {/* <p className='mb-0'>PZLX rewards: +3.14%</p> */}

                                    </Tooltip>
                                }
                            >
                                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    // <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )

            }
        },

        {
            key: "boost",
            text: "Boost",
            className: "boost",
            sortable: true,
            align: "left",
            cell: record => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{roundToSignificant(record.boostPoints, 6)} PZLX</p>
                        </div>
                    </>
                );

            }

        },
        {
            key: "action",
            text: "",
            className: "action",
            sortable: true,
            align: "left",
            cell: record => {
                return (
                    <div className='d-flex align-items-center justify-content-around'>
                        <button className='btn_trans_icon_table' disabled={parseFloat(record.boostPoints) > 0 ? false : true} onClick={() => { setViewrecord(record); setUnboostmodal(true); }}>
                            <FaMinus />
                        </button>

                        <button className='btn_trans_icon_table' onClick={() => { setViewrecord(record); setBoostmodal(true); }}>
                            <FaPlus />
                        </button>
                    </div>
                )

            }
        },


    ];

    const showsuccesModal = (text, title) => {
        try {
            settitle(title)
            setloaderText(text)
            setsuccessLoader(false)
            setModalsucess(true);
        } catch (err) {
            console.log(err, 'showsuccesModal___err')
        }
    }

    const SetsuccessLoader = (text, hash) => {
        setTrxn(hash)
        setsuccessText(text);
        setsuccessLoader(true)
    }

    const setError = (text) => {
        seterrorText(text);
        setsuccessLoader(undefined)
    }

    const userdetail = useSelector((state) => state.admin)

    useEffect(() => {
        if (!isEmpty(positionList)) {
            if (filter == 'Boosted') {
                BoostedOnly([...positionList])
            } else if (filter == 'Locked') {
                LockedOnly([...positionList])
            }
            else if (filter == 'Yield_bearing') {
                BreaingOnly([...positionList])
            }
            else {
                setRecords([...positionList])
            }
        }
    }, [filter, positionList])

    const BoostedOnly =(records)=>{
        try{
            const Record =  records.filter((val)=>{
                let poolData   =  nftpooldata.find((vals)=>(vals.poolAddress == val.poolAddress)) 
                return val.boostPoints > 0 && !parseFloat(val.lockDuration) > 0 && !(parseFloat(poolData.allocPoint) > 0)
            })
            setRecords(Record)
        }catch(err){
            console.log(err,'BoostedOnly__err')
        }
    }

    const LockedOnly =(records)=>{
        try{
            const Record =  records.filter((val)=>{
                let poolData   =  nftpooldata.find((vals)=>(vals.poolAddress == val.poolAddress)) 
                return parseFloat(val.lockDuration) > 0 && !val.boostPoints > 0 && !(parseFloat(poolData.allocPoint) > 0)
            })
            setRecords(Record)
        }catch(err){
            console.log(err,'BoostedOnly__err')
        }
    }

    const BreaingOnly =(records)=>{
        try{
            const Record =  records.filter((val)=>{
                let poolData   =  nftpooldata.find((vals)=>(vals.poolAddress == val.poolAddress)) 
                return !val.boostPoints > 0 && !parseFloat(val.lockDuration) > 0 && parseFloat(poolData.allocPoint) > 0
            })
            setRecords(Record)
        }catch(err){
            console.log(err,'BoostedOnly__err')
        }
    }
    return (
        <>
            <div className={`${cascading.innerpages} ${cascading.launchpadpage} ${cascading.pooltable} ${cascading.yieldtablewidth}`}>


                <div className={`${cascading.tabstyle} mt-3 table-responsive`} id='launchpadtable' >

                    <ReactDatatable
                        className={`${cascading.datatablestyle} ${cascading.datatablestylenohover}`}
                        config={config}
                        columns={columns}
                        records={records}
                        onChange={(e) => {
                            console.log(e, 'ReactDatatable')
                        }}
                    />
                </div>
            </div>

            {boostmodal && <Boostmodal
                show={boostmodal}
                onSuccess={(text, title) => {
                    showsuccesModal(text, title);
                    setBoostmodal(false)
                }}
                SetsuccessLoader={(text, hash) => {
                    SetsuccessLoader(text, hash)
                }}
                setError={(text) => {
                    setError(text)
                }}
                onHide={() => { setBoostmodal(false) }}
                record={viewrecord}
            />}
            {unboostmodal && <Unboostmodal
                show={unboostmodal}
                onSuccess={(text, title) => {
                    showsuccesModal(text, title);
                    setUnboostmodal(false)
                }}
                SetsuccessLoader={(text, hash) => {
                    SetsuccessLoader(text, hash)
                }}
                setError={(text) => {
                    setError(text)
                }}
                onHide={() => { setUnboostmodal(false) }}
                record={viewrecord} />}

            {modalsucess && <Addsuccessmodal
                show={modalsucess}
                title={title}
                loaderText={loaderText}
                successText={successText}
                isLoader={successLoader}
                lpSymbol={viewrecord.lpsymbol}
                hash={Trxn}
                errorText={errorText}
                onHide={() => { setModalsucess(false) }}
            />}
            {modalcoming && <ComingSoonmodal show={modalcoming} onHide={() => { setmodalcoming(false); }} />}


        </>

    )
}
