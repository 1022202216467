import React, { useEffect, useState } from 'react'
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown, FaArrowRight } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';




import { useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";

//import 
import { getWalletAddress } from '../lib/localStorage';
import { toFixedNumber } from '../lib/FixedNumber';
import { roundToSignificant } from '../hooks/useCommon';
//validation
import { validateAllocation } from '../validation/LaunchValidation'
import { validateDeallocation } from '../validation/LaunchValidation'
//hooks
import { GettotalAllocationFloor, GetMaxBonus, GetExpectBoostMultipler, GetPoolTotalAllocation, getYeildAddress } from '../hooks/useyeildBooster'
import { ApproveUsage, CheckApproveUsage } from '../hooks/useXtoken';
import { BoostPosition, UnBoostPosition, GetBoostMultipler } from '../hooks/useyeildBooster';
import { GetBounsApr, GetMultiplierByBoostPoints, UsePoolcontract } from '../hooks/useNFTpool'
import { isEmpty } from '../lib/isEmpty';
export default function Boostpositionmodal(props) {

  const [boostpositionmodal, setBoostpositionmodal] = useState(true);
  const [amount, setAmount] = useState('')
  const [isyielded, setIsyielded] = useState(true);
  const [islocked, setIslocked] = useState(true);
  const [isboosted, setIsboosted] = useState(false);

  const [ActiveTab, setActiveTab] = useState('boost')
  const [multiper, setmultiper] = useState('0')
  const [Exmultiper, setExmultiper] = useState('0')
  const [approveXToken, setApproveXToken] = useState(false)
  const [errors, setErrors] = useState('')
  const [poolInfo, setPoolInfo] = useState({})
  const [bonusPerc, setbonusPerc] = useState('0')
  const [bonusApr, setbonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const [isApprove, setisApprove] = useState(false)
  const [btnenable, setbtnenable] = useState(false)
  const { positionrecord } = useSelector((state) => (state.position))
  const { nftpooldata } = useSelector((state) => (state.nftpool))
  const { onSuccess, SetsuccessLoader, setError } = props

  const { xtokenbalance } = useSelector((state) => (state.xtoken))
  const { allocationDetails } = useSelector((state) => (state.yield))


  const setInitialState = () => {
    try {
      setAmount('')
      setExmultiper('0')
      let bonusPerc = positionrecord?.boostMultiplier * 100
      setbonusPerc(bonusPerc)
    } catch (err) {
      console.log(err, 'setInitialState__Err')
    }
  }


  const CalculateBonusApr = () => {
    try {
      if (!isEmpty(poolInfo) && !isEmpty(positionrecord)) {
        let bonusPercentage = parseFloat(positionrecord.lockMultiplier) * 100 + bonusPerc
        let BonusApr = GetBounsApr({
          annualInterestRate: poolInfo.FramBaseApr,
          bonusPercentage: bonusPercentage
        })
        BonusApr = isNaN(BonusApr) ? 0 : BonusApr
        setbonusApr(BonusApr)
        let totalApr = parseFloat(BonusApr) + parseFloat(poolInfo.FramBaseApr) + parseFloat(poolInfo.SwapFeeApr)
        settotalApr(totalApr)
      }

    } catch (err) {
      console.log('CalculateBonusApr__err')
    }
  }
  useEffect(() => {
    CalculateBonusApr()
  }, [bonusPerc, poolInfo])

  useEffect(() => {
    let poolInfo = nftpooldata.find((val) => (val?.poolAddress?.toLowerCase() == positionrecord?.poolAddress?.toLowerCase()))
    setPoolInfo(poolInfo)
  }, [nftpooldata, positionrecord])
  useEffect(() => {
    let bonusPerc = positionrecord?.boostMultiplier * 100
    setbonusPerc(bonusPerc)
  }, [positionrecord])

  // useEffect(() => {
  //   setMultipler()
  // }, [poolInfo, positionrecord])
  //onChange func
  const SetMax = () => {
    try {
      if (ActiveTab == 'boost') {
        setAmount(xtokenbalance)
        IsApproveUsage(xtokenbalance)
        setExpectMultipler(xtokenbalance)
      } else if (ActiveTab == 'unboost') {
        setAmount(positionrecord.boostPoints)
        setExpectMultipler(positionrecord.boostPoints)
      }
    } catch (err) {
      console.log(err, 'SetMax__err')
    }
  }

  const handleChange = (e) => {
    try {
      const { name, value } = e.target
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(value) && value !== "") {
        return false
      }
      if (name == 'amount') {
        setAmount(value)
        IsApproveUsage(value)
        if (value != '')
          setExpectMultipler(value)
      }
    } catch (err) {
      console.log(err, 'onChange__err')
    }
  }

  const approveUsage = async () => {
    try {
      setisApprove(true)
      setbtnenable(true)
      const account = getWalletAddress()
      let Amount = (amount * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      console.log(Amount, 'approveUsage')
      let result = await ApproveUsage(getYeildAddress(), Amount, account)
      if (result) {
        setisApprove(false)
        setbtnenable(false)
        setApproveXToken(true)
      } else {
        setisApprove(false)
        setbtnenable(false)
        setApproveXToken(false)
      }
    } catch (err) {
      setisApprove(false)
      setbtnenable(false)
      setApproveXToken(false)
      console.log(err, 'approveUsage__err')
    }
  }

  const IsApproveUsage = async (value) => {
    try {
      const account = getWalletAddress()
      let Amount = (value * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      let result = await CheckApproveUsage(getYeildAddress(), Amount, account)
      if (result) {
        setApproveXToken(true)
      } else {
        setApproveXToken(false)
      }
    } catch (err) {
      console.log(err, 'approveUsage__err')
    }
  }

  const Boost = async () => {
    try {
      let value = {
        amount: amount,
      }
      let validation = validateAllocation(value, xtokenbalance)
      if (!isEmpty(validation)) {
        setErrors(validation);
        return false
      }
      let Amount = (amount * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      let loaderText = 'Boosting position.....'
      onSuccess(loaderText, 'Boost position')
      let result = await BoostPosition(positionrecord.poolAddress, positionrecord.tokenId, Amount)
      if (result) {
        let successLoaderText = `You've successfully allocated ${amount} PZLX to your ${positionrecord.lpsymbol} position`
        SetsuccessLoader(successLoaderText, result.transactionHash)
      } else {
        let errorText = `Error ! When Boost to position`
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'Boost__err')
    }
  }

  const UnBoost = async () => {
    try {

      let value = {
        amount: amount,
        allocBal: positionrecord.boostPoints
      }
      let validation = validateDeallocation(value)
      if (!isEmpty(validation)) {
        setErrors(validation);
        return false
      }
      let Amount = (amount * 10 ** 18).toFixed(0)
      Amount = toFixedNumber(Amount)
      // let Amount = toFixedNumber(amount * 10 ** 18)
      // Amount = parseFloat(Amount).toFixed(0)
      let loaderText = 'Unboosting position.....'
      onSuccess(loaderText, 'Unboost position')
      let result = await UnBoostPosition(positionrecord.poolAddress, positionrecord.tokenId, Amount)
      if (result) {
        let successLoaderText = `You've successfully deallocated ${amount} PZLX to your ${positionrecord.lpsymbol} position`
        SetsuccessLoader(successLoaderText, result.transactionHash)
      } else {
        let errorText = `Error ! When Unboost to position`
        setError(errorText)
      }
    } catch (err) {
      console.log(err, 'Boost__err')
    }
  }

  // const setMultipler = async () => {
  //   try {
  //     let poolAddress = positionrecord.poolAddress
  //     let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier * 10000)
  //     let lpAmount = toFixedNumber(positionrecord.amount * 10 ** 18)
  //     let totalLpSupply = poolInfo.totalLpSupply.toString()
  //     let userAllocation = toFixedNumber(allocationDetails.UserTotalAllocation * 10 ** 18)
  //     let multiperVal = await GetBoostMultipler(poolAddress, maxBoostMultiplier.toString(), lpAmount.toString(), userAllocation.toString(), totalLpSupply.toString())
  //     console.log(multiperVal, 'multiperVal', poolInfo.lpSupply)
  //     setmultiper(multiperVal)
  //   } catch (err) {
  //     console.log(err, 'setMultipler__err')
  //   }
  // }

  const setExpectMultipler = async (amount) => {
    try {
      let lpAmount = toFixedNumber(positionrecord.amount * 10 ** 18)
      lpAmount = lpAmount.toString()
      let boostPoint = 0
      if (ActiveTab == 'boost') {
        boostPoint = parseFloat(positionrecord?.boostPoints) + parseFloat(amount)
        boostPoint = toFixedNumber(parseFloat(boostPoint) * 10 ** 18)
        boostPoint = boostPoint.toString()
      }
      else {
        boostPoint = parseFloat(positionrecord.boostPoints) - parseFloat(amount)
        boostPoint = toFixedNumber(parseFloat(boostPoint) * 10 ** 18)
        boostPoint = boostPoint.toString()
      }
      console.log('setExpectMultipler', boostPoint, positionrecord?.poolAddress, lpAmount)
      let boostmultiplier = await GetMultiplierByBoostPoints(positionrecord.poolAddress, lpAmount, boostPoint)
      setExmultiper(boostmultiplier)
      setbonusPerc(boostmultiplier * 100)
    } catch (err) {
      console.log(err, 'setExpectMultipler___err')
    }
  }

  const SetMaxBonus = async () => {
    try {
      let poolAllocation = await GetPoolTotalAllocation(positionrecord.poolAddress)
      let lpSupply = poolInfo.lpSupply.toString()
      let multiper = toFixedNumber(poolInfo.maxBoostMultiplier * 10 ** 4)
      let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier * 10 ** 4)
      let lpAmount = toFixedNumber(positionrecord.amount * 10 ** 18)
      let totalAllocationFloor = await GettotalAllocationFloor()
      let MaxAllocation = GetMaxBonus(multiper, lpSupply, maxBoostMultiplier, lpAmount, poolAllocation, totalAllocationFloor)
    } catch (err) {
      console.log(err, 'SetMaxBonus__err')
    }
  }
  console.log(Exmultiper, 'bonusApr')
  // const setExpectMultipler = async (amount) => {
  //   try {
  //     let poolAddress = positionrecord.poolAddress
  //     let maxBoostMultiplier = toFixedNumber(poolInfo.maxBoostMultiplier * 10000)
  //     let lpAmount = toFixedNumber(positionrecord.amount * 10 ** 18)
  //     let totalLpSupply = poolInfo.totalLpSupply.toString()
  //     let userAllocation = toFixedNumber(allocationDetails.UserTotalAllocation * 10 ** 18)
  //     let poolAllocation = await GetPoolTotalAllocation(poolAddress)
  //     if (ActiveTab == 'boost') {
  //       userAllocation = userAllocation + toFixedNumber((amount) * 10 ** 18)
  //       poolAllocation = parseFloat(poolAllocation) + toFixedNumber((amount) * 10 ** 18)
  //     } else {
  //       userAllocation = userAllocation - toFixedNumber((amount) * 10 ** 18)
  //       poolAllocation = parseFloat(poolAllocation) - toFixedNumber((amount) * 10 ** 18)
  //     }
  //     console.log(poolAllocation, 'poolAllocation', poolInfo.lpSupply)
  //     let multiperVal = await GetExpectBoostMultipler(maxBoostMultiplier.toString(), lpAmount.toString(), userAllocation.toString(), totalLpSupply.toString(), poolAllocation.toString())
  //     console.log(multiperVal, 'setExpectMultipler', poolInfo.lpSupply)
  //     setExmultiper(multiperVal)
  //   } catch (err) {
  //     console.log(err, 'setMultipler__err')
  //   }
  // }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={boostpositionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Boost/Unboost Position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={() => { props.onHide(); props.onhideposition(); }} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>{positionrecord?.lpsymbol}</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{positionrecord?.tokenId}</p>
                </div>


              </div>


              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              <p className={`${cascading.tokenvaluedesc} mt-3`}>{ActiveTab == 'boost' ? 'Allocate PZLX to your spNFT for more yield' : 'Deallocate PZLX boost from your spNFT'}</p>
            </div>
            <div className={`${cascading.tabstyle} mt-5`}>
              <Tabs
                defaultActiveKey="boost"
                id="uncontrolled-tab-example"
                className={`${cascading.tabstyletabs} mt-3 mb-5`}
                onSelect={(e) => { console.log(e, 'setActiveTab'); setInitialState(); setActiveTab(e) }}
              >
                <Tab eventKey="boost" title="Boost">

                  <>

                    <p className={`${cascading.innerheadwhitetitle} mt-2`}>Amount
                    </p>
                    {/* <p className={`${cascading.tokenvaluedesc} text-start`}>You need to own {positionrecord?.lpsymbol} LP tokens to directly add more liquidity to this position. If that's not the case, head to the liquidity page first.</p> */}


                    <div className={`${cascading.inputsec} my-3`}>
                      <div className={`input-group mb-3`}>
                        <input
                          type="text"
                          className={`form-control`}
                          placeholder="0"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={amount}
                          name='amount'
                          onChange={(e) => { handleChange(e) }}
                        />
                        <div className={`input-group-append`} >
                          <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { SetMax() }}>
                            Max
                          </button>
                        </div>
                      </div>
                      <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                      <p className={`${cascading.balsec}`}>balance : {xtokenbalance} PZLX</p>
                    </div>

                    <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
                    </p>


                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Boost Multiplier</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>x{positionrecord.boostMultiplier} <FaArrowRight /> x{Exmultiper}</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.FramBaseApr, 4)}%</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Bonus APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(bonusApr, 6)}%</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Earned fees APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.SwapFeeApr, 4)}%</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalApr, 4)}%</p>
                      </div>
                    </div>






                    <div className='mt-4'>
                      {
                        isEmpty(amount)
                          ? <button disabled={true} className={`btn ${cascading.connectwalletbtn}`} type='button'>Enter amount</button>
                          :
                          approveXToken ?
                            <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { Boost() }}>Boost</button>
                            :
                            <button className={`btn ${cascading.connectwalletbtn}`} type='button' disabled={btnenable} onClick={() => { approveUsage() }}>{isApprove ? 'Approving...' : 'Approve'}</button>
                      }

                      <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

                    </div>
                  </>
                </Tab>
                <Tab eventKey="unboost" title="Unboost">

                  <>

                    <p className={`${cascading.innerheadwhitetitle} mt-2`}>Amount
                    </p>
                    {/* <p className={`${cascading.tokenvaluedesc} text-start`}>You need to own {positionrecord.lpsymbol} LP tokens to directly add more liquidity to this position. If that's not the case, head to the liquidity page first.</p> */}


                    <div className={`${cascading.inputsec} my-3`}>
                      <div className={`input-group mb-3`}>
                        <input
                          type="text"
                          className={`form-control`}
                          placeholder="0"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={amount}
                          name='amount'
                          onChange={(e) => { handleChange(e) }}
                        />
                        <div className={`input-group-append`} >
                          <button className={` ${cascading.inputspan}`} id="basic-addon1" onClick={() => { SetMax() }}>
                            Max
                          </button>
                        </div>
                      </div>
                      <p className="text-danger f-12 d-block text-left">{errors?.amount}</p>
                      <p className={`${cascading.balsec}`}>allocated : {positionrecord.boostPoints} PZLX</p>
                    </div>

                    <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
                    </p>


                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Boost Multiplier</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>x{positionrecord.boostMultiplier} <FaArrowRight /> x{Exmultiper}</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.FramBaseApr, 4)}%</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Bonus APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(bonusApr, 6)}%</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Earned fees APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(poolInfo.SwapFeeApr, 4)}%</p>
                      </div>
                    </div>

                    <div className='row mt-3'>
                      <div className='col-8 col-md-8'>
                        <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                      </div>
                      <div className='col-4 col-md-4'>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadwhitesmbrkval}`}>{roundToSignificant(totalApr, 4)}%</p>
                      </div>
                    </div>






                    <div className='mt-4'>
                      {
                        isEmpty(amount) ?
                          <button disabled={true} className={`btn ${cascading.connectwalletbtn}`} type='button'>Enter amount</button> :
                          <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { UnBoost() }}>Unboost</button>
                      }

                      <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

                    </div>
                  </>
                </Tab>

              </Tabs>
            </div>
            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

