import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdSettings } from "react-icons/io";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdHistory } from "react-icons/md";
import cascading from '../../assests/css/liquidity.module.css';
import Footer from '../../common/footer'
import { FaArrowDown } from "react-icons/fa";
import Header from '../../common/header';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaAngleDown, FaPlus, FaArrowLeft } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import bnbimg from '../../assests/images/bnb.png'
import Walletmodal from '../../modals/walletmodal';
import { useSelector } from 'react-redux';
import Recenthistory from '../../modals/recenthistory';
import Selecttoken from '../../modals/selecttoken';
import { Tooltip } from 'react-bootstrap';
import Settingsmodal from '../../modals/settingsmodal';
import ConfirmRemovemodal from '../../modals/confirmRemovemodal'
import CompletedRemovemodal from '../../modals/completedRemovemodal';
import WaitingRemovemodal from '../../modals/waitingRemovemodal';
import coinimg from '../../assests/images/infoicon.png'
export default function Yumremoveliquidity() {
    const navigate = useNavigate()
    const [swapsec, setSwapsec] = useState(true)
    const [walletmodalshow, setWalletmodalshow] = useState(false)
    const [recentshow, setRecentshow] = useState(false);
    const [tokenshow, setTokenshow] = useState(false);
    const [settingshow, setSettingshow] = useState(false);
    const [commontoken, setCommontoken] = useState(false);
    const [removetab, setRemovetab] = useState(true)
    const userdetail = useSelector((state) => state.admin)
    const [confirmRemove, setConfirmRemove] = useState(true)
    const [succesSupply, setSuccesSupply] = useState(false)
    const [waitingRemove, setWaitingRemove] = useState(false)

    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.liquiditypage}  ${cascading.removepage}`}>
                <div className={`container`}>
                    <div className={`row justify-content-center ${cascading.swapcardrow}`}>
                        <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5`}>
                            <div className={` ${cascading.cardstyle}`}>
                                <div className={`${cascading.toprow}`}>
                                    <div className={`${cascading.backsec}`}>
                                        <div className={`${cascading.backbtn}`}>
                                            <Link to="/liquidity">  <FaArrowLeft fill='#fff' fontSize={18} /></Link>
                                        </div>
                                        <div>
                                            <p className={`${cascading.headings}`}>Remove BNB-PZLX liquidity</p>
                                            <p className={`${cascading.desc}`}>To receive BNB and PZLX  </p>


                                        </div>

                                    </div>

                                </div>
                                <div className={`${cascading.tabrow}`}>
                                    <p className={`${cascading.labelname}`}>Amount</p>
                                    <button className={`btn ${cascading.tabbtn}`} onClick={() => setRemovetab(!removetab)}>{removetab ? "Simple" : "Detailed"}</button>

                                </div>
                                {removetab ? <>

                                    <div className={`${cascading.inputsec} mb-4`}>
                                        <div className={`${cascading.balancerow}`}>
                                            <p className={`${cascading.inputlabel}`}>Input</p>
                                            {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : 0.2155</p>}
                                        </div>

                                        <div className={`${cascading.inputsecrow}`}>
                                            <div >
                                                <input type="text" className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" />
                                            </div>

                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={() => { setTokenshow(true); setCommontoken(true) }}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB <FaAngleDown fill='#fff' /></button>
                                            </div>



                                        </div>

                                    </div>
                                    <div className={`row ${cascading.arrowrow}`} >
                                        <div className={`${cascading.swaparrow}`}>
                                            <FaArrowDown />
                                        </div>
                                    </div>
                                    <div className={`${cascading.inputsec} mb-4`}>
                                        <div className={`${cascading.balancerow}`}>
                                            <p className={`${cascading.inputlabel}`}>Output</p>
                                            {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : 0</p>}
                                        </div>

                                        <div className={`${cascading.inputsecrow}`}>
                                            <div >
                                                <input type="email" className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" />
                                            </div>

                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={() => { setTokenshow(true); setCommontoken(true) }}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB <FaAngleDown fill='#fff' /></button>
                                            </div>


                                        </div>

                                    </div>
                                    <div className={`row ${cascading.arrowrow}`} >
                                        <div className={`${cascading.swaparrow}`}>
                                            <FaPlus />
                                        </div>
                                    </div>
                                    <div className={`${cascading.inputsec} mb-4`}>
                                        <div className={`${cascading.balancerow}`}>
                                            <p className={`${cascading.inputlabel}`}>Output</p>
                                            {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : 0</p>}
                                        </div>

                                        <div className={`${cascading.inputsecrow}`}>
                                            <div >
                                                <input type="email" className={`from-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0.0" />
                                            </div>

                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={() => { setTokenshow(true); setCommontoken(true) }}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB <FaAngleDown fill='#fff' /></button>
                                            </div>


                                        </div>

                                    </div>
                                    <div className={`${cascading.pricesec}`}>
                                        <p className='text-white'>Prices</p>
                                        <div className={`${cascading.pricecard}`}>
                                            <ul>
                                                <li>
                                                    <p>1 BNB =</p>
                                                    <p>666666 PZLX</p>
                                                </li>
                                                <li>
                                                    <p>1 PZLX =</p>
                                                    <p>666666 BNB</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </> :

                                    <>
                                    <div className={`${cascading.rangesec}`}>
                                        <p className={`${cascading.rangeshow}`}>0 %</p>

                                        <input type="range" className={`form-range ${cascading.rangeinput}`} id="customRange" name="points" min="0" max="100"/>
                                        <div className={`${cascading.btngrpsec} ${cascading.btngrpsecnowrap}`}>
                                            <button className={`btn ${cascading.btnstyle}`}>25%</button>
                                            <button className={`btn ${cascading.btnstyle}`}>50%</button>
                                            <button className={`btn ${cascading.btnstyle}`}>75%</button>
                                            <button className={`btn ${cascading.btnstyle}`}>Max</button>


                                        </div>

                                    </div>
                                    <div className={`row ${cascading.arrowrow}`} >
                                        <div className={`${cascading.swaparrow}`}>
                                            <FaArrowDown />
                                        </div>
                                    </div>




                                        <div className={`${cascading.pricesec}`}>
                                            <p className={`text-white ${cascading.pricename}`}>You will Receive</p>
                                            <div className={`${cascading.pricecard}`}>
                                                <ul>
                                                    <li>
                                                        <p><img src={coinimg} className={`img-fluid ${cascading.coinimg}`}/> BNB </p>
                                                        <p>-</p>
                                                    </li>
                                                    <li>
                                                        <p><img src={coinimg} className={`img-fluid ${cascading.coinimg}`}/> PZLX</p>
                                                        <p>-</p>
                                                    </li>
                                                    <li>
                                                        <p></p>
                                                        <p>Receive WBNB</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className={`${cascading.pricesec}`}>
                                            <p className={`text-white ${cascading.pricename}`}>Prices</p>
                                            <div className={`${cascading.pricecard}`}>
                                                <ul>
                                                    <li>
                                                        <p>1 BNB =</p>
                                                        <p>666666 PZLX</p>
                                                    </li>
                                                    <li>
                                                        <p>1 PZLX =</p>
                                                        <p>666666 BNB</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>




                                }


                                <div className={`${cascading.btnsec}`}>
                                    <button className={`btn ${cascading.connect_btn}`} onClick={() => setWalletmodalshow(true)} disabled>Enabled</button>
                                    <button className={`btn ${cascading.connect_btn}`} >Remove</button>


                                </div>
                            </div>
                            <div className={`${cascading.balancecardsec} mt-4`}>
                                <p className={`${cascading.head}`}>LP tokens in your wallet</p>
                                <ul>
                                    <li>
                                        <p><img src={coinimg} className={`img-fluid ${cascading.coinimg}`} /> <img src={coinimg} className={`img-fluid ${cascading.coinimg}`} />  BNB-DT LP</p>
                                        <p>0.0003162</p>
                                    </li>
                                    <li>
                                        <p>Share of Pool</p>
                                        <p>100.0000%</p>
                                    </li>
                                    <li>
                                        <p>Pooled BNB</p>
                                        <p>0.00000010001</p>
                                    </li>
                                    <li>
                                        <p>Pooled BNB</p>
                                        <p>99.9901</p>
                                    </li>
                                </ul>

                            </div>

                        </div>

                    </div>


                </div>


            </div>



            <Footer />
            {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
            {recentshow && <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />}
            {tokenshow && <Selecttoken show={tokenshow} commontoken={commontoken} onHide={() => { setTokenshow(false); setCommontoken(false) }} />}
            {settingshow && <Settingsmodal show={settingshow} onHide={() => setSettingshow(false)} />}
            {confirmRemove && <ConfirmRemovemodal show={confirmRemove} 
            // supplyData={() => { supplyData() }} 
            // supplyObj={obj} pairInfo={pairInfo} 
            onHide={() => setConfirmRemove(false)} />}
            
            {succesSupply && <CompletedRemovemodal show={succesSupply} onHide={() => setSuccesSupply(false)} />}
            
            {waitingRemove && <WaitingRemovemodal show={waitingRemove} 
            // supplyObj={obj} 
            onHide={() => setWaitingRemove(false)} />}


        </>
    )
}
