import React, { useEffect, useState } from 'react';
import { IoMdSettings } from "react-icons/io";
import { MdHistory } from "react-icons/md";
import cascading from '../../assests/css/swap.module.css';
import Footer from '../../common/footer'
import { FaArrowDown } from "react-icons/fa";
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { FaSyncAlt } from "react-icons/fa";


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegQuestionCircle } from "react-icons/fa";
import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import bnbimg from '../../assests/images/bnb.png'
import Walletmodal from '../../modals/walletmodal';
import { useSelector } from 'react-redux';
import Recenthistory from '../../modals/recenthistory';
import Selecttoken from '../../modals/selecttoken';
import Settingsmodal from '../../modals/settingsmodal';
import ConfirmSwapmodal from '../../modals/confirmSwapmodal';

import coinimg from '../../assests/images/infoicon.png'
import { ZEROTH_ADDRESS } from '../../config/env'
import BigNumber from 'bignumber.js';
import WaitingSwapmodal from '../../modals/waitingSwapmodal';
import CompletedSwapmodal from '../../modals/completedSwapmodal';

//import lib
import { consolelog } from '../../lib/consolelog';
import { isEmpty } from '../../lib/isEmpty';
import fileObjectUrl from '../../lib/img'

import { BLOCKED_PRICE_IMPACT_NON_EXPERT, ALLOWED_PRICE_IMPACT_HIGH, ALLOWED_PRICE_IMPACT_MEDIUM, ALLOWED_PRICE_IMPACT_LOW } from '../../config/env'

//hooks
import { GetPairInfo } from '../../hooks/useFactory';
import { GetAmountsIn, GetAmountsOut, getRouterAddress } from '../../hooks/useRouter';
import { toFixedNumber } from '../../lib/FixedNumber';
import { getSlippage, getWalletAddress } from '../../lib/localStorage';
import { multicall } from '../../hooks/useMultiCall';
import { SwapExactETHForTokens, SwapETHForExactTokens, SwapExactTokensForETH, SwapTokensForExactETH, SwapExactTokensForTokens, SwapTokensForExactTokens } from '../../hooks/useRouter';
import { UseAllCommonPairs } from '../../hooks/useTokens';
import { UseTradeExactIn, UseTradeExactOut } from '../../hooks/useExactTrade';
import { CheckIsApproved, ApproveToken } from '../../hooks/useTokens';

//import abi
import pairABI from '../../config/abi/pairAbi.json'

//config
import { MAX_UNIT256 } from '../../config/env';
import { UnWrap, Wrap } from '../../hooks/useErc20';
import CompletedWrapmodal from '../../modals/compeleteWrapmodal';
import toast, { Toaster } from 'react-hot-toast';
import { IsCurrency, IsWrappedCurrency, roundToSignificant } from '../../hooks/useCommon';



export default function Swap() {
    const navigate = useNavigate()
    const [token0, setToken0] = useState('')
    const [token1, setToken1] = useState('')
    const [swapsec, setSwapsec] = useState(true)
    const [walletmodalshow, setWalletmodalshow] = useState(false)
    const [recentshow, setRecentshow] = useState(false);
    const [tokenshowA, setTokenshowA] = useState(false);
    const [tokenshowB, setTokenshowB] = useState(false);
    const [settingshow, setSettingshow] = useState(false)
    const [tokenA, setTokenA] = useState({})
    const [tokenB, setTokenB] = useState({})
    const [tokenAval, setTokenAval] = useState('')
    const [tokenBval, setTokenBval] = useState('')
    const [lpAddress, setlpAddress] = useState('')
    const [price, setPrice] = useState('')
    const [MiniReceived, setMiniReceived] = useState('')
    const [MaxSold, setMaxSold] = useState('')
    const [ReserveA, setReserveA] = useState('')
    const [ReserveB, setReserveB] = useState('')
    const [priceImpact, setpriceImpact] = useState('')
    const [lpFee, setlpFee] = useState('')
    const [disableSwap, setdisableSwap] = useState(false)
    const [SwapShow, setSwapShow] = useState('')
    const [isFromEdit, setisFromEdit] = useState(true)
    const [isToEdit, setisToEdit] = useState(false)
    const [confirmShow, setCofirmShow] = useState(false)
    const [waitingShow, setWaitingShow] = useState(false)
    const [compeletedShow, setCompeletedShow] = useState(false)
    const [approve, setApprove] = useState(false)
    const [addsend, setAddsend] = useState(false);
    const [recipient, setRecipient] = useState('')
    const [Routepath, setRoutepath] = useState([])
    const [Route, setRoute] = useState('')
    const [priceDis, setpriceDis] = useState(true)
    const [Insufficient, setInsufficient] = useState(false)
    const [wrapShow, setwrapShow] = useState(false)
    const [wrap, setWrap] = useState(undefined)

    const [insuffBalance, setInsuffBalance] = useState(false)
    const [insuffText, setinsuffText] = useState('')

    const [wrapTnx, setWrapTnx] = useState('')

    //selector
    const userdetail = useSelector((state) => state.admin)
    const walletData = useSelector((state) => state.wallet)
    const { slippage, deadline, expertmode, multihops } = useSelector((state) => state.settings)
    console.log("userdetail", userdetail, multihops)

    useEffect(() => {
        let Wrapp = wrap
        console.log('useEffect1', lpAddress, wrap, !isEmpty(tokenA) && !isEmpty(tokenB), IsCurrency(tokenA) && IsWrappedCurrency(tokenB))
        CheckBalance()
        if (!isEmpty(tokenA) && !isEmpty(tokenB)) {
            if (IsCurrency(tokenA) && IsWrappedCurrency(tokenB)) {
                setWrap(true)
                Wrapp = true
                setApprove(true)
                setInsufficient(false)
                setTokenBval(tokenAval)
                setlpAddress(ZEROTH_ADDRESS)
                console.log(tokenAval, 'useEffect3')
            } else if (IsWrappedCurrency(tokenA) && IsCurrency(tokenB)) {
                setWrap(false)
                Wrapp = false
                setInsufficient(false)
                console.log(tokenAval, 'useEffect2')
                setlpAddress(ZEROTH_ADDRESS)
                setTokenBval(tokenAval)
            } else {
                setWrap(undefined)
                Wrapp = undefined
                console.log('useEffect')
                if (isFromEdit) {
                    setTokenBval('')
                }
                if (isToEdit) {
                    setTokenBval('')
                }
                getPairAddress()
            }
        }
    }, [tokenA, tokenB])

    useEffect(() => {
        if (!isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined) {
            console.log('useEffect4', lpAddress, !isEmpty(parseFloat(tokenAval)), parseFloat(tokenAval), tokenAval)
            // UseTradeExactIn(tokenAval, tokenA.address, tokenB.address)   
            if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
                if (isFromEdit) {
                    getAmountOut(tokenAval, tokenA.address, tokenB.address, swapsec)
                }
                CheckApprove()
            }
        }
        if (!isEmpty(tokenA) && !isEmpty(tokenB) && !isEmpty(parseFloat(tokenBval)) && !isNaN(parseFloat(tokenBval)) && wrap == undefined) {
            console.log('useEffect5', lpAddress, !isEmpty(parseFloat(tokenBval)), tokenAval)
            if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
                if (isToEdit) {
                    getAmountIn(tokenBval, tokenA.address, tokenB.address, swapsec)
                }
                CheckApprove()
            }
        }
    }, [multihops, lpAddress, wrap])

    useEffect(() => {
        console.log('MidPrice2', !isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(parseFloat(tokenBval)) && !isNaN(parseFloat(tokenBval)), tokenAval, tokenBval)
        if (!isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(parseFloat(tokenBval)) && !isNaN(parseFloat(tokenBval))) {
            console.log('MidPrice2')
            MidPrice(tokenBval, tokenAval)
        }
    }, [swapsec])


    const CheckBalance = () => {
        try {
            if (getWalletAddress()) {
                if (!isEmpty(parseFloat(tokenAval) && !isNaN(parseFloat(tokenAval)))) {
                    if (tokenAval > tokenA.balance) {
                        setInsuffBalance(true)
                        setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                    } else {
                        setInsuffBalance('')
                        setinsuffText(``)
                    }
                }
            }
        } catch (err) {
            console.log(err, "CheckBalance___err")
        }
    }
    const CheckApprove = async () => {
        try {
            // if (tokenA.symbol == 'BNB') {
            //     setApprove(true)
            //     return true
            // }
            if (IsCurrency(tokenA)) {
                setApprove(true)
                return true
            }
            let account = getWalletAddress()
            let amount = toFixedNumber(parseFloat(tokenAval) * 10 ** 18).toString()
            let isApproved = await CheckIsApproved(getRouterAddress(), amount, account, tokenA.address)
            setApprove(isApproved)
        } catch (err) {
            consolelog('CheckApprove__err', err, true)
        }
    }

    const ApproveCall = async () => {
        try {
            let spenderAddress = getRouterAddress()
            let amount = toFixedNumber(parseFloat(tokenAval) * 10 ** 18).toString()
            let account = getWalletAddress()
            let isApproved = await CheckIsApproved(spenderAddress, amount, account, tokenA.address)
            if (isApproved) {
                setApprove(true)
                return true
            }
            let approve = await ApproveToken(spenderAddress, MAX_UNIT256, account, tokenA.address)
            if (approve) {
                setApprove(true)
            }
        } catch (err) {
            consolelog('CheckApprove__err', err, true)
        }
    }

    //get function
    const getPairAddress = async () => {
        try {
            let pairaddress = await GetPairInfo(tokenB.address, tokenA.address)

            if (pairaddress != ZEROTH_ADDRESS) {
                let calls = [
                    {
                        address: pairaddress,
                        name: 'getReserves',
                    },
                    {
                        address: pairaddress,
                        name: 'token0',
                    },
                    {
                        address: pairaddress,
                        name: 'token1',
                    }
                ]
                var pooldata = await multicall(pairABI, calls)
                let Swapsec = swapsec
                if (pooldata) {
                    let token0 = pooldata[1][0]
                    let token1 = pooldata[2][0]
                    let reserveA = new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18
                    let reserveB = new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18
                    if (reserveA == 0 && reserveB == 0) {
                        setInsufficient(true)
                    }
                    console.log("getPairAddress", pooldata[1][0], pooldata[2][0], reserveA, reserveB, pairaddress)
                    if (tokenA.address.toLowerCase() == token1.toLowerCase() && tokenB.address.toLowerCase() == token0.toLowerCase()) {
                        console.log('condition__Swapsec', false)
                        Swapsec = false
                        setSwapsec(false)
                    } else {
                        console.log('condition__Swapsec', true)
                        Swapsec = true
                        setSwapsec(true)
                    }
                    setToken0(token0)
                    setToken1(token1)
                    setReserveA(toFixedNumber(new BigNumber(pooldata[0][0]._hex).toNumber() / 10 ** 18))
                    setReserveB(toFixedNumber(new BigNumber(pooldata[0][1]._hex).toNumber() / 10 ** 18))
                }
                setlpAddress(pairaddress)
                if (isFromEdit) {
                    getAmountOutCall(tokenAval, pairaddress, Swapsec)
                } else {
                    getAmountnInCall(tokenBval, pairaddress, Swapsec)
                }
                return true
            }
            setlpAddress(pairaddress)
        } catch (err) {
            consolelog("getPairAddress__err", err, true)
        }
    }

    const getAmountOut = async (tokenAval, tokenAaddress, tokenBaddress, Swapsec) => {
        try {
            console.log(multihops, 'multihops')
            if (multihops) {
                let { priceImpact, amountOut, routePath, route, realizedLPFee, status } = await UseTradeExactIn(tokenAval, tokenAaddress, tokenBaddress)
                console.log(routePath, 'routePath')
                if (status) {
                    if (!isNaN(amountOut)) {
                        setInsufficient(false)
                        setTokenBval(amountOut)
                        miniReciviedOut(amountOut)
                        setRoutepath(routePath)
                        setRoute(route)
                        setlpFee(parseFloat(realizedLPFee).toFixed(16))
                        // MidPrice(amountOut)
                        priceImpactValidation(priceImpact)
                        priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
                        setpriceImpact(priceImpact)
                    }
                    let price = tokenAval / amountOut
                    if (!isNaN(price)) {
                        setPrice(price)
                    }
                } else {
                    let AddressArr = [tokenAaddress, tokenBaddress]
                    let amountsIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
                    let { price, amountOut, status } = await GetAmountsOut(AddressArr, amountsIn)
                    consolelog('getAmountOut', { price, amountOut }, true)
                    if (!status) {
                        console.log("useEffect10");
                        setInsufficient(true)
                        setTokenBval('')
                    } else {
                        setInsufficient(false)
                        if (!isNaN(amountOut)) {
                            setRoutepath([])
                            setRoute('')
                            setTokenBval(amountOut)
                            miniReciviedOut(amountOut)
                            MidPrice(amountOut, tokenAval, Swapsec)
                            LpFee(tokenAval)
                        }
                        if (!isNaN(price)) {
                            setPrice(price)
                        }
                    }

                }
            } else {
                let AddressArr = [tokenAaddress, tokenBaddress]
                let amountsIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
                let { price, amountOut, status } = await GetAmountsOut(AddressArr, amountsIn)
                consolelog('getAmountOut', { price, amountOut }, true)
                if (!status) {
                    console.log("useEffect14");
                    setInsufficient(true)
                    setTokenBval('')
                } else {
                    setInsufficient(false)
                    if (!isNaN(amountOut)) {
                        console.log("useEffect11");
                        setRoutepath([])
                        setRoute('')
                        setTokenBval(amountOut)
                        miniReciviedOut(amountOut)
                        MidPrice(amountOut, tokenAval, Swapsec)
                        LpFee(tokenAval)
                    }
                    if (!isNaN(price)) {
                        setPrice(price)
                    }
                }

            }
        } catch (err) {
            consolelog("getPairAddress__err", err, true)
            setInsufficient(true)

        }
    }

    const getAmountIn = async (tokenBval, tokenAaddress, tokenBaddress, Swapsec) => {
        try {
            console.log(multihops, 'multihops', tokenBval)
            if (multihops) {
                let { priceImpact, amountIn, routePath, route, realizedLPFee, status } = await UseTradeExactOut(tokenBval, tokenAaddress, tokenBaddress)
                console.log(routePath, 'getAmountIn_routePath')
                if (status) {
                    if (!isNaN(amountIn)) {
                        setInsufficient(false)
                        setTokenAval(amountIn)
                        maximumsold(amountIn)
                        setRoutepath(routePath)
                        setRoute(route)
                        setlpFee(parseFloat(realizedLPFee).toFixed(16))
                        // MidPrice(amountOut)
                        priceImpactValidation(priceImpact)
                        priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
                        setpriceImpact(priceImpact)
                        console.log(amountIn > tokenA.balance, amountIn, tokenA.balance, 'amountIn > tokenA.balance')
                        if (amountIn > tokenA.balance) {
                            setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                            setInsuffBalance(true)
                            // setTokenAval(amountIn)
                        } else {
                            setinsuffText('')
                            setInsuffBalance(false)
                        }
                    }
                    let price = amountIn / tokenBval
                    if (!isNaN(price)) {
                        setPrice(price)
                    }
                } else {
                    let AddressArr = [tokenAaddress, tokenBaddress]
                    let amountsOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
                    console.log(amountsOut, "amountIn", tokenBval)
                    let { price, amountIn, status } = await GetAmountsIn(AddressArr, amountsOut)
                    consolelog('getAmountIn', { price, amountIn }, true)

                    if (!status) {
                        setInsufficient(true)
                        setTokenAval('')
                    } else {
                        setInsufficient(false)
                        if (!isNaN(amountIn)) {
                            setRoutepath([])
                            setRoute('')
                            setTokenAval(amountIn)
                            maximumsold(amountIn)
                            MidPrice(tokenBval, amountIn, Swapsec)
                            LpFee(amountIn)
                        }
                        if (!isNaN(price)) {
                            setPrice(price)
                        }
                        if (amountIn > tokenA.balance) {
                            setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                            setInsuffBalance(true)
                            // setTokenAval(amountIn)
                        } else {
                            setinsuffText('')
                            setInsuffBalance(false)
                        }
                    }

                }
            } else {
                let AddressArr = [tokenAaddress, tokenBaddress]
                let amountsIn = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
                console.log(amountsIn, "amountsOut", tokenBval)
                let { price, amountIn, status } = await GetAmountsIn(AddressArr, amountsIn)

                if (!status) {
                    setInsufficient(true)
                    setTokenAval('')
                } else {
                    setInsufficient(false)
                    consolelog('getAmountOut', { price, amountIn }, true)
                    if (!isNaN(amountIn)) {
                        setRoutepath([])
                        setRoute('')
                        setTokenAval(amountIn)
                        maximumsold(amountIn)
                        MidPrice(tokenBval, amountIn, Swapsec)
                        LpFee(amountIn)
                    }
                    if (!isNaN(price)) {
                        setPrice(price)
                    }
                    if (amountIn > tokenA.balance) {
                        setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                        setInsuffBalance(true)
                        // setTokenAval(amountIn)
                    } else {
                        setinsuffText('')
                        setInsuffBalance(false)
                    }
                }

            }
        } catch (err) {
            console.log(err, "getAmountIn__err")
            setInsufficient(true)

        }
    }

    const getAmountOutCall = (tokenAval, lpAddress, Swapsec) => {
        try {
            if (!isEmpty(parseFloat(tokenAval)) && !isNaN(parseFloat(tokenAval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined) {
                console.log('useEffect6', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
                // UseTradeExactIn(tokenAval, tokenA.address, tokenB.address)
                if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
                    if (isFromEdit) {
                        getAmountOut(tokenAval, tokenA.address, tokenB.address, Swapsec)
                    }
                    CheckApprove()
                }
            }
        } catch (err) {
            console.log(err, "getAmountCall__err")
        }
    }

    const getAmountnInCall = (tokenBval, lpAddress, Swapsec) => {
        try {
            if (!isEmpty(tokenA) && !isEmpty(tokenB) && !isEmpty(parseFloat(tokenBval)) && !isEmpty(parseFloat(tokenBval)) && wrap == undefined) {
                console.log('useEffect7', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
                if (lpAddress !== ZEROTH_ADDRESS && !isEmpty(lpAddress)) {
                    if (isToEdit) {
                        console.log('call__UseTradeExactIn3', lpAddress, parseFloat(tokenAval), !isEmpty(parseFloat(tokenAval)))
                        getAmountIn(tokenBval, tokenA.address, tokenB.address, Swapsec)

                    }
                    CheckApprove()
                }

            }
        } catch (err) {
            console.log(err, "getAmountCall__err")
        }
    }


    //Change func
    const onTokenAChange = (value) => {
        try {
            // let check = !((tokenB.symbol == 'BNB' && value.symbol == 'WBNB') || (tokenB.symbol == 'WBNB' && value.symbol == 'BNB'))
            let check = !((IsCurrency(tokenB) && IsWrappedCurrency(value)) || (IsWrappedCurrency(tokenB) && IsCurrency(value)))
            console.log(check, 'onTokenAChange')
            if (value.address == tokenB?.address && check) {
                setTokenB(tokenA)
            }
            setTokenA(value);
            if (value.balance <= 0 || tokenAval > value.balance) {
                setInsuffBalance(true)
                setinsuffText(`Insufficient ${value.symbol} balance`)
            } else {
                setInsuffBalance(false)
                setinsuffText('')
            }
        } catch (err) {
            consolelog("HandleChange__err", err, true)
        }
    }
    const onTokenBChange = (value) => {
        try {
            // let check = !((tokenA.symbol == 'BNB' && value.symbol == 'WBNB') || (tokenA.symbol == 'WBNB' && value.symbol == 'BNB'))
            let check = !((IsCurrency(tokenA) && IsWrappedCurrency(value)) || (IsWrappedCurrency(tokenA) && IsCurrency(value)))
            console.log(check, 'onTokenBChange', tokenB)
            if (value.address == tokenA?.address && check) {
                setTokenA(tokenB)
            }
            setTokenB(value);
        } catch (err) {
            consolelog("HandleChange__err", err, true)
        }
    }

    const handleChange = (e) => {
        try {
            const { value, id } = e.target
            var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
            setInsufficient(false)
            consolelog('handleChange', { val: value, id: id, 'con': !numbers.test(value) && value !== "" }, true)
            if (!numbers.test(value) && value !== "") {
                return false
            }
            if (id == 'tokenAval') {
                if (value > tokenA.balance) {
                    setinsuffText(`Insufficient ${tokenA.symbol} balance`)
                    setInsuffBalance(true)
                } else {
                    setinsuffText('')
                    setInsuffBalance(false)
                }
                setisFromEdit(true)
                setisToEdit(false)
                console.log("useEffect12");
                setTokenBval('')
                setTokenAval(value)
                console.log('handleChange__wrap', wrap)
                if (wrap == true || wrap == false) {
                    console.log('handleChange__wrap')
                    setTokenBval(value)
                } else {
                    getAmountOutCall(value, lpAddress, swapsec)
                }
            }
            if (id == 'tokenBval') {
                // if(value > tokenA.balance){
                //     setSwapShow(`Insufficient ${tokenB.symbol} balance`)
                //     setdisableSwap(true)
                // }
                setisToEdit(true)
                setisFromEdit(false)
                setTokenAval('')
                setTokenBval(value)
                if (wrap == true || wrap == false) {
                    console.log('handleChange__wrap')
                    setTokenAval(value)
                } else {
                    getAmountnInCall(value, lpAddress)
                }
            }
        } catch (err) {
            consolelog("HandleChange__err", err, true)
        }
    }

    //calulation 
    const miniReciviedOut = (amountOut) => {
        try {

            //minimumReceived
            let slippageval = parseFloat(slippage) / 100
            var minimumReceived = parseFloat(amountOut) * (1 - slippageval);
            minimumReceived = toFixedNumber(minimumReceived)
            // minimumReceived = Math.round(minimumReceived)
            setMiniReceived(minimumReceived)


            consolelog('miniRecivied', { minimumReceived: minimumReceived }, true)

        } catch (err) {
            consolelog('miniRecivied__err', err, true)
        }
    }

    const maximumsold = (amountIn) => {
        try {

            //minimumReceived
            let slippageval = parseFloat(slippage) / 100
            var minimumReceived = parseFloat(amountIn) * (1 + slippageval);
            minimumReceived = toFixedNumber(minimumReceived)
            // minimumReceived = Math.round(minimumReceived)
            setMaxSold(minimumReceived)


            consolelog('miniRecivied', { minimumReceived: minimumReceived }, true)

        } catch (err) {
            consolelog('miniRecivied__err', err, true)
        }
    }

    const LpFee = (amountIn) => {
        try {
            //lp fee
            let realizedLPFee = amountIn * 0.25 / 100
            setlpFee(toFixedNumber(realizedLPFee))
        } catch (err) {
            consolelog('LpFee__err', err, true)
        }
    }

    console.log(swapsec, 'MidPrice1')
    const MidPrice = (amountOut, amountIn, swapsec) => {
        try {
            console.log(swapsec, 'MidPrice')
            let midprice
            if (swapsec) {
                midprice = Number(ReserveB) / Number(ReserveA)
            } else {
                midprice = Number(ReserveA) / Number(ReserveB)
            }
            var exactQuote = (midprice) * parseFloat(amountIn)
            var priceImpact = ((exactQuote - amountOut) / exactQuote)
            priceImpact = (priceImpact - (0.25 / 100)) * 100
            console.log(priceImpact, "priceImpact1", ReserveA, ReserveB)
            priceImpactValidation(priceImpact)
            priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
            setpriceImpact(priceImpact)
            // }
            // else {
            //         midprice = Number(ReserveB) / Number(ReserveA)
            //         var exactQuote = (midprice) * parseFloat(amountIn)
            //         var priceImpact = ((exactQuote - amountOut) / exactQuote)
            //         priceImpact = (priceImpact - (0.25 / 100)) * 100
            //         console.log(priceImpact, "priceImpact")
            //         priceImpactValidation(priceImpact)
            //         priceImpact = parseFloat(priceImpact.toFixed(2)) < 0.01 ? '<0.01' : priceImpact.toFixed(2)
            //         setpriceImpact(priceImpact)
            // }
        } catch (err) {
            consolelog('MidPrice__err', err, true)
        }
    }

    //validation 
    const priceImpactValidation = (priceImpact) => {
        try {
            if (!(priceImpact < BLOCKED_PRICE_IMPACT_NON_EXPERT)) {
                setSwapShow('Price Impact High')
                setdisableSwap(true)
                return 4
            }
            if (!(priceImpact < ALLOWED_PRICE_IMPACT_HIGH)) {
                setSwapShow('Swap Anyway')
                setdisableSwap(false)
                return 3
            }
            if (!(priceImpact < ALLOWED_PRICE_IMPACT_MEDIUM)) {
                setSwapShow('Swap')
                setdisableSwap(false)
                return 2
            }
            if (!(priceImpact < ALLOWED_PRICE_IMPACT_LOW)) {
                setSwapShow('Swap')
                setdisableSwap(false)
                return 1
            }
            else {
                setSwapShow('Swap')
                setdisableSwap(false)
                return 0
            }
        } catch (err) {
            consolelog('priceImpactValidation__err', err, true)
        }
    }



    //submit 
    const SwapSubmit = async () => {
        try {
            setWaitingShow(true)
            let to = expertmode && !isEmpty(recipient) ? recipient : getWalletAddress()
            // console.log(tokenA.symbol == 'BNB' && isFromEdit, tokenA.symbol == 'BNB' && isToEdit, 'SwapSubmit', tokenA.symbol, tokenB.symbol)
            if (IsCurrency(tokenA) && isFromEdit) {
                let amountETH = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
                let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0)
                let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
                let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
                let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
                let result = await SwapExactETHForTokens(amountETH, amountOutMin, path, to, deadLine, qureyString)
                if (result) {
                    setWaitingShow(false)
                    setCompeletedShow(true)
                    initalStateSet()
                    return true
                } else {
                    setCofirmShow(true)
                    setWaitingShow(false)
                }
            }
            else if (IsCurrency(tokenA) && isToEdit) {
                let amountETH = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
                let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
                let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
                // let to = getWalletAddress()
                let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
                console.log('SwapETHForExactTokens')
                let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
                let result = await SwapETHForExactTokens(amountETH, amountOut, path, to, deadLine, qureyString)
                if (result) {
                    setWaitingShow(false)
                    setCompeletedShow(true)
                    initalStateSet()
                } else {
                    setCofirmShow(true)
                    setWaitingShow(false)
                }

            }
            else if (IsCurrency(tokenB) && isFromEdit) {
                let amountIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
                let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0)
                let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
                // let to = getWalletAddress()
                let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
                let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
                let result = await SwapExactTokensForETH(amountIn, amountOutMin, path, to, deadLine, qureyString)
                if (result) {
                    setWaitingShow(false)
                    setCompeletedShow(true)
                    initalStateSet()
                } else {
                    setCofirmShow(true)
                    setWaitingShow(false)
                }

            }
            else if (IsCurrency(tokenB) && isToEdit) {
                let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
                let amountInMax = toFixedNumber(MaxSold * 10 ** 18).toFixed(0)
                let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
                // let to = getWalletAddress()
                let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
                let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
                let result = await SwapTokensForExactETH(amountOut, amountInMax, path, to, deadLine, qureyString)
                if (result) {
                    setWaitingShow(false)
                    setCompeletedShow(true)
                    initalStateSet()
                } else {
                    setCofirmShow(true)
                    setWaitingShow(false)
                }

            }
            else if (isFromEdit) {
                let amountIn = toFixedNumber(tokenAval * 10 ** 18).toFixed(0)
                let amountOutMin = toFixedNumber(MiniReceived * 10 ** 18).toFixed(0)
                let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
                // let to = getWalletAddress()
                let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
                let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
                let result = await SwapExactTokensForTokens(amountIn, amountOutMin, path, to, deadLine, qureyString)
                if (result) {
                    setWaitingShow(false)
                    setCompeletedShow(true)
                    initalStateSet()
                } else {
                    setCofirmShow(true)
                    setWaitingShow(false)
                }

            }
            else if (isToEdit) {
                let amountOut = toFixedNumber(tokenBval * 10 ** 18).toFixed(0)
                let amountInMax = toFixedNumber(MaxSold * 10 ** 18).toFixed(0)
                let path = isEmpty(Routepath) ? [tokenA.address, tokenB.address] : Routepath
                // let to = getWalletAddress()
                let deadLine = (new Date().getTime() / 1000 + parseFloat(deadline * 60)).toFixed(0)
                let qureyString = `Swap ${toFixedNumber(tokenAval)} ${tokenA.symbol} for ${toFixedNumber(tokenBval)} ${tokenB.symbol}`
                let result = await SwapTokensForExactTokens(amountOut, amountInMax, path, to, deadLine, qureyString)
                if (result) {
                    setWaitingShow(false)
                    setCompeletedShow(true)
                    initalStateSet()
                } else {
                    setCofirmShow(true)
                    setWaitingShow(false)
                }
            }
        } catch (err) {
            consolelog('SwapSubmit_err', err, true)
        }
    }

    const WrapAndUnwarp = async () => {
        try {
            if (wrap) {
                let amount = toFixedNumber(tokenAval * 10 ** tokenA.decimal)
                amount = parseFloat(amount).toFixed(0)
                let wrapres = await Wrap(tokenB.address, amount)
                if (wrapres) {
                    setWrapTnx(wrapres)
                    setwrapShow(true)
                } else {
                    toast.error('Try again', {
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    });
                }
            } else if (wrap == false) {
                let amount = toFixedNumber(tokenAval * 10 ** tokenB.decimal)
                amount = parseFloat(amount).toFixed(0)
                let unwrapres = await UnWrap(tokenA.address, amount)
                if (unwrapres) {
                    setWrapTnx(unwrapres)
                    setwrapShow(true)
                } else {
                    toast.error('Try again', {
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    });
                }
            }
        } catch (err) {
            console.log(err, 'WrapAndUnwarp__err')
        }
    }

    const initalStateSet = () => {
        try {
            setTokenAval(0)
            setTokenBval(0)
            setMiniReceived(0)
            setMaxSold(0)
            setisFromEdit(true)
            setisToEdit(false)
            setpriceImpact(0)
            setPrice(0)
            setlpFee(0)
            setRecipient('')
            setSwapsec(true)
            setTokenA({})
            setTokenB({})
        } catch (err) {
            consolelog('initalStateSet__err', err, true)
        }
    }

    console.log('tokenBval', expertmode, tokenA, tokenBval, true)

    return (
        <>
            <Header />
            <div className={`${cascading.innerpages} ${cascading.swappage}`}>
                <div className={`container`}>
                    <div className={`row justify-content-center ${cascading.swapcardrow}`}>
                        <div className={`col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 `}>
                            <div className={` ${cascading.cardstyle}`}>
                                <div className={`${cascading.toprow}`}>
                                    <div>
                                        <p className={`${cascading.headings}`}>Swap</p>
                                        <p className={`${cascading.desc}`}>Trade tokens in an instant</p>
                                    </div>
                                    <div className={`${cascading.iconsec}`}>
                                        <IoMdSettings onClick={() => setSettingshow(true)} />
                                        <MdHistory onClick={() => setRecentshow(true)} />
                                    </div>
                                </div>
                                <div className={`${cascading.inputsec} mb-4`}>
                                    <div className={`${cascading.balancerow}`}>
                                        <p className={`${cascading.inputlabel}`}>From {!isFromEdit ? '(estimated)' : ''}</p>
                                        {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : {isEmpty(tokenA) ? 0 : roundToSignificant(tokenA.balance, 6)}</p>}
                                    </div>

                                    <div className={`${cascading.inputsecrow}`}>
                                        <div >
                                            <input
                                                autoComplete={"off"}
                                                type="text"
                                                className={`from-control`}
                                                id="tokenAval"
                                                aria-describedby="emailHelp"
                                                placeholder="0.0"
                                                value={tokenAval}
                                                onChange={handleChange} />
                                        </div>
                                        {/* {swapsec ?
                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshow(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB <FaAngleDown fill='#fff' /></button>
                                            </div>
                                            : */}
                                        <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                            {(userdetail?.walletStatus == 'connect') && !isEmpty(tokenA) &&
                                                <button
                                                    className={`btn ${cascading.currencybtn}`}
                                                    onClick={() => {
                                                        setTokenAval(tokenA?.balance)
                                                    }}> Max </button>}
                                            <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshowA(true)}>
                                                <img src={isEmpty(tokenA) ? fileObjectUrl(coinimg) : fileObjectUrl(tokenA.image)} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />
                                                {isEmpty(tokenA) ? 'Select currency' : tokenA.symbol} <FaAngleDown fill='#fff' /></button>
                                        </div>

                                        {/* } */}


                                    </div>

                                </div>
                                <div className={`row ${cascading.arrowrow}`}
                                    onClick={() => {
                                        setisFromEdit(!isFromEdit)
                                        setisFromEdit(!isToEdit)
                                        setTokenA(tokenB)
                                        setTokenB(tokenA)
                                        setTokenAval(tokenBval)
                                        setTokenBval(tokenAval)
                                    }
                                    }>
                                    <div className={`${cascading.swaparrow}`}>
                                        <FaArrowDown />
                                    </div>

                                </div>
                                <div className={`${cascading.inputsec} mb-4`}>
                                    <div className={`${cascading.balancerow}`}>
                                        <p className={`${cascading.inputlabel}`}>To {!isToEdit ? '(estimated)' : ''}</p>
                                        {(userdetail.walletStatus == 'connect') && <p className={`${cascading.inputlabel}`}>Balance : {isEmpty(tokenB) ? 0 : roundToSignificant(tokenB.balance, 6)}</p>}
                                    </div>

                                    <div className={`${cascading.inputsecrow}`}>
                                        <div >
                                            <input
                                                type="type"
                                                autoComplete={"off"}
                                                className={`from-control`}
                                                id="tokenBval"
                                                aria-describedby="emailHelp"
                                                value={tokenBval}
                                                onChange={handleChange}
                                                placeholder="0.0" />
                                        </div>
                                        {/* {!swapsec ?
                                            <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                                {(userdetail.walletStatus == 'connect') && <button className={`btn ${cascading.currencybtn}`}> Max </button>}
                                                <button className={`btn ${cascading.currencybtn}`} onClick={()=> setTokenshow(true)}><img src={bnbimg} alt='bnb' className={`img-fluid ${cascading.coinimg}`} /> BNB <FaAngleDown fill='#fff' /></button>
                                            </div>
                                            : */}
                                        <div className={` ${cascading.rightbtnsec}`}>
                                            <button className={`btn ${cascading.currencybtn}`} onClick={() => setTokenshowB(true)}>
                                                <img src={isEmpty(tokenB) ? fileObjectUrl(coinimg) : fileObjectUrl(tokenB.image)} alt='bnb' className={`img-fluid ${cascading.coinimg}`} />
                                                {isEmpty(tokenB) ? 'Select a Currency' : tokenB.symbol} <FaAngleDown fill='#fff' /></button>
                                        </div>

                                        {/* } */}

                                    </div>

                                </div>
                                {(expertmode) && wrap == undefined ?
                                    !addsend ?
                                        <p className={`${cascading.cursorpointer} text-center text-white`} onClick={() => { setAddsend(true) }}>Add a send (optional)</p>
                                        :
                                        <>
                                            <div className={`row d-flex align-items-center justify-content-ceenter text-center ${cascading.arrowrow}`}>
                                                <div className={`${cascading.swaparrow}`}>
                                                    <FaArrowDown />
                                                </div>
                                                <div>

                                                    <p className={`${cascading.cursorpointer} text-center text-white`} onClick={() => { setAddsend(false) }}>Remove send</p>


                                                </div>

                                            </div>
                                        </> : ''
                                }
                                {expertmode && addsend && wrap == undefined &&
                                    <div className={`${cascading.valuecardsec} mb-4`}>
                                        <div className={`${cascading.secrow}`}>
                                            <p className={`mb-0 ${cascading.label}`}>Recipient</p>

                                        </div>

                                        <div className={`${cascading.inputsecrowecipeinet}`}>
                                            <div >
                                                <input
                                                    type="text"
                                                    autoComplete={"off"}
                                                    className={`from-control`}
                                                    id="recipient"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Wallet address or ENS name"
                                                    onChange={(e) => {
                                                        setRecipient(e.target.value)
                                                    }}
                                                    value={recipient}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                }


                                {!isEmpty((tokenAval)) && !isEmpty((tokenBval)) && !isEmpty(tokenA) && !isEmpty(tokenB) && wrap == undefined ?
                                    <div className={`${cascading.valuecardsec} mb-4`}>
                                        <div className={`${cascading.secrow}`}>
                                            <p className={`mb-0 ${cascading.label}`}>Price</p>
                                            <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(price, 6)} {''}
                                                {priceDis && priceDis ? `${tokenA.symbol} per ${tokenB.symbol}` : `${tokenB.symbol} per ${tokenA.symbol}`}
                                                <button className={`${cascading.roundiconbtn}`}
                                                    onClick={() => {
                                                        setpriceDis(!priceDis)
                                                        if (!priceDis) {
                                                            let price = tokenAval / tokenBval
                                                            setPrice(price)
                                                        } else {
                                                            let price = tokenBval / tokenAval
                                                            setPrice(price)
                                                        }
                                                    }}>
                                                    <FaSyncAlt /></button></p>
                                        </div>
                                        <div className={`mt-3 pb-2 ${cascading.secrow}`}>
                                            <p className={`mb-0 ${cascading.label}`}>Slippage Tolerance</p>
                                            <p className={`mb-0 ${cascading.labels}`}>{slippage}%
                                            </p>
                                        </div>
                                    </div> : ""}
                                <div className={`${cascading.btnsec}`}>
                                    {(userdetail.walletStatus == null || userdetail.walletStatus == 'disconnect') ?
                                        <button className={`btn ${cascading.connect_btn}`}
                                            onClick={() => setWalletmodalshow(true)}>Connect Wallet</button> :
                                        (lpAddress == ZEROTH_ADDRESS || Insufficient) && wrap == undefined ?
                                            <button
                                                className={`btn ${cascading.connect_btn}`}
                                            >
                                                Insufficient liquidity for this trade.
                                            </button>
                                            :
                                            (isToEdit && isEmpty(tokenBval)) || isFromEdit && isEmpty(tokenAval) ?
                                                <button className={`btn ${cascading.connect_btn}`} disabled={true}>
                                                    Enter an Amount</button> :
                                                (isEmpty(tokenA) || isEmpty(tokenB)) ?
                                                    <button
                                                        className={`btn ${cascading.connect_btn}`}
                                                        disabled={true}
                                                    >
                                                        Select a token
                                                    </button> :
                                                    ((isFromEdit && isEmpty(tokenBval)) || (isToEdit && isEmpty(tokenAval))) ?
                                                        <button
                                                            className={`btn ${cascading.connect_btn}`}
                                                            disabled={true}
                                                        >
                                                            Loading...
                                                        </button>
                                                        :
                                                        approve ?
                                                            insuffBalance ?
                                                                <button
                                                                    className={`btn ${cascading.connect_btn}`}
                                                                    disabled={true}
                                                                >
                                                                    {insuffText}
                                                                </button> :
                                                                wrap ?
                                                                    <button
                                                                        className={`btn ${cascading.connect_btn}`}
                                                                        onClick={() => {
                                                                            // setCofirmShow(true)\
                                                                            WrapAndUnwarp()
                                                                            // SwapSubmit()
                                                                        }}
                                                                    >
                                                                        Wrap
                                                                    </button> :
                                                                    wrap == false ?
                                                                        <button
                                                                            className={`btn ${cascading.connect_btn}`}
                                                                            disabled={disableSwap}
                                                                            onClick={() => {
                                                                                // setCofirmShow(true)
                                                                                WrapAndUnwarp()
                                                                                // SwapSubmit()
                                                                            }}
                                                                        >
                                                                            UnWrap
                                                                        </button> :
                                                                        <button
                                                                            className={`btn ${cascading.connect_btn}`}
                                                                            disabled={disableSwap}
                                                                            onClick={() => {
                                                                                setCofirmShow(true)
                                                                                // SwapSubmit()
                                                                            }}
                                                                        >
                                                                            {SwapShow}
                                                                        </button>
                                                            :
                                                            <button
                                                                className={`btn ${cascading.connect_btn}`}
                                                                onClick={() => {
                                                                    ApproveCall()
                                                                    // SwapSubmit()
                                                                }}
                                                            >
                                                                Enable {tokenA.symbol}
                                                            </button>
                                    }


                                </div>
                            </div>


                            {console.log(isFromEdit && !isEmpty(tokenBval), tokenBval, isToEdit && !isEmpty(parseFloat(tokenAval)), 'isToEdit && !isEmpty(parseFloat(tokenAval))')}
                            {(isFromEdit && !isEmpty(tokenBval) || isToEdit && !isEmpty(tokenAval)) && wrap == undefined ?

                                <div className={`mt-3 pb-1 ${cascading.cardstyle}`}>
                                    <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                                        <div className={`${cascading.secrow} ${cascading.secrowcolmob}`}>
                                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{isFromEdit ? 'Minimum Received' : isToEdit ? 'Maximum sold' : ''}
                                                <OverlayTrigger
                                                    className={`${cascading.tooltip_style}`}
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </p>
                                            <p className={`mb-0 ${cascading.labels}`}>{isFromEdit ? `${roundToSignificant(MiniReceived,6)} ${tokenB.symbol}` : isToEdit ? `${roundToSignificant(MaxSold,6)} ${tokenA.symbol}` : ''}
                                            </p>
                                        </div>

                                        <div className={`mt-3 ${cascading.secrow} ${cascading.secrowcolmob}`}>
                                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Price Impact
                                                <OverlayTrigger
                                                    className={`${cascading.tooltip_style}`}
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            The difference between the market price and estimated price due to trade size.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </p>
                                            <p className={`mb-0 ${cascading.labels}`}>45.07%
                                            </p>
                                        </div>


                                        <div className={`mt-3 ${cascading.secrow} ${cascading.secrowcolmob}`}>
                                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Liquidity Provider Fee
                                                <OverlayTrigger
                                                    className={`${cascading.tooltip_style}`}
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-top`}>
                                                            <p className='mb-1'>For each trade a 0.25% fee is paid</p>
                                                            <p className='mb-1'>- 0.17% to LP token holders</p>
                                                            <p className='mb-1'>- 0.03% to the Treasury</p>
                                                            <p className='mb-1'>- 0.05% towards DSP buyback and burn</p>


                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </p>
                                            <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(lpFee,6)} {tokenA.symbol}
                                            </p>
                                        </div>
                                        {Route && !isEmpty(Route) ?
                                            <div className={`mt-3 ${cascading.secrow} ${cascading.secrowcolmob}`}>
                                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Route</p>
                                                <p className={`mb-0 ${cascading.labels}`}>{Route}
                                                </p>
                                            </div> : ''}
                                    </div>

                                </div> :""}
                        </div>

                    </div>


                </div>


            </div>



            <Footer />
            {compeletedShow && <CompletedSwapmodal show={compeletedShow} onHide={() => setCompeletedShow(false)} />}
            {waitingShow && <WaitingSwapmodal show={waitingShow} onHide={() => { setWaitingShow(false) }} tokenA={tokenA} tokenB={tokenB} tokenAval={tokenAval} tokenBval={tokenBval} />}
            {confirmShow && <ConfirmSwapmodal show={confirmShow} onHide={() => { setCofirmShow(false) }} onConfirm={() => { SwapSubmit() }} price={price} miniRecivied={MiniReceived} maxSold={MaxSold} priceImpact={priceImpact} lpFee={lpFee} tokenA={tokenA} tokenB={tokenB} tokenAval={tokenAval} tokenBval={tokenBval} isToEdit={isToEdit} isFromEdit={isFromEdit} />}
            {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}
            {recentshow && <Recenthistory show={recentshow} onHide={() => setRecentshow(false)} />}
            {tokenshowA && <Selecttoken show={tokenshowA} SetToken={(data) => { onTokenAChange(data) }} onHide={() => setTokenshowA(false)} />}
            {tokenshowB && <Selecttoken show={tokenshowB} SetToken={(data) => { onTokenBChange(data) }} onHide={() => setTokenshowB(false)} />}
            {settingshow && <Settingsmodal show={settingshow} onHide={() => setSettingshow(false)} />}
            {wrapShow && <CompletedWrapmodal show={wrapShow} onHide={() => setwrapShow(false)} txn={wrapTnx} />}
         </>
    )
}
