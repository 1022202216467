import React, { useState } from 'react'
import cascading from '../assests/css/all.module.css';
import Walletmodal from '../modals/walletmodal';

import { Button, ButtonGroup } from 'react-bootstrap';
import { FaTrophy } from "react-icons/fa";

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';
export default function Connectcard(props) {
   
    const [showmodal, setShowmodal] = useState(false);

 
    return (
        <div className={`mb-4`}>
       
            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx} ${cascading.headflexnewwhite}`}>
                 Connect Wallet
<div>
                 
              

                </div>

                </p>
                <p className={`${cascading.desc} mt-4`}>
                {/* Connect wallet to earn real yield weekly  protocol earnings with PZLX. */}
                Connect wallet to earn with dividends.

                </p>
           

          <div className='text-center mt-4'>
          <button className={`btn ${cascading.stakebtnicon}`} onClick={()=> setShowmodal(true)}>
                 Connect Wallet
                </button>
          </div>


           


            </div>




            {showmodal && <Walletmodal show={showmodal} onHide={() => setShowmodal(false)} />}


        </div>
    )
}
