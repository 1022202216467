import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaInfoCircle, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';





import { IoMdClose } from "react-icons/io";


import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from '../lib/isEmpty';
import { toFixedNumber } from '../lib/toFixedOf';
import { getWalletAddress } from '../lib/localStorage';

//constant
import { ALLOCATE_DETAILS } from '../constants';
//hooks
import { UnBoostPosition, BoostPosition, getAllocationDetails } from '../hooks/useyeildBooster';
import { getYeildAddress } from '../hooks/useyeildBooster'
import { ApproveUsage, CheckApproveUsage, XBalance } from '../hooks/useXtoken';
import { sleep } from '../config/tokens';
import { GetuserPositions } from '../hooks/useNFTpool';
import { roundToSignificant } from '../hooks/useCommon';

export default function Applyallocationmodal(props) {
  const dispatch = useDispatch()
  const [applyallocationmodal, setApplyallocationmodal] = useState(true);
  const [newAllocate, setnewAllocate] = useState(true);
  const [currAllocate, setcurrAllocate] = useState(true);
  const [loader, setloader] = useState(false);
  const { allocateDetails, allocationDetails } = useSelector((state) => (state.yield))
  const {xtokenbalance} = useSelector((state)=>(state.xtoken))
  const { nftpooldata } = useSelector((state) => (state.nftpool))

  useEffect(() => {
    if (!isEmpty(allocateDetails)) {
      let newAllocate = allocateDetails.reduce((total, currentValue) => {
        console.log(currentValue.type == 'allocate', "currentValue.type == 'allocate'")
        if (currentValue.type == 'allocate') {
          console.log(currentValue.type == 'allocate', "currentValue.type == 'allocate'", parseFloat(total) + parseFloat(currentValue.amount))
          return total = parseFloat(total) + parseFloat(currentValue.amount);
        } else {
          return total
        }
      }, 0)
      console.log(newAllocate, 'newAllocate', allocateDetails.length)
      setnewAllocate(newAllocate)
      setcurrAllocate(allocationDetails.totalAllocation)
    }
  }, [allocateDetails])

  const handleSumbit = async () => {
    try {
      setloader(true)
      let res = []
      if(parseFloat(newAllocate) > parseFloat(xtokenbalance)){
        toast.error('Insufficient balance')
        return true
      }
      for (let i = 0; i < allocateDetails.length; i++) {
        let val = allocateDetails[i]
        let Amount = toFixedNumber(val.amount * 10 ** 18)
        Amount = parseFloat(Amount).toFixed(0)
        if (val.type == 'allocate') {
          let isApprove
          let CheckisApproved = await IsApproveUsage(Amount)
          if (!CheckisApproved) {
            isApprove = await approveUsage(Amount)
          } else {
            isApprove = CheckisApproved
          }
          if (isApprove) {

            let result = await BoostPosition(val.poolAddress, val.tokenId, Amount)
            sleep(1000)
            if (result) {
              setcurrAllocate(parseFloat(currAllocate) + parseFloat(val.amount))
              setnewAllocate(parseFloat(newAllocate) - parseFloat(val.amount))
              res.push(result)
            }
          }
        } else if (val.type == 'deallocate') {
          let result = await UnBoostPosition(val.poolAddress, val.tokenId, Amount)
          if (result) {
            res.push(result)
            setcurrAllocate(parseFloat(currAllocate) - parseFloat(val.amount))
          }
        }
        if (allocateDetails.length - 1 == res.length - 1) {
          setloader(false)
          dispatch({
            type: ALLOCATE_DETAILS,
            payload: []
          })
          getAllocationDetails(dispatch)
          GetuserPositions(nftpooldata, dispatch)
          XBalance(getWalletAddress(),dispatch)
          props.onHide()
        }
      }
    } catch (err) {
      console.log(err, 'handleSumbit__err')
    }
  }

  const IsApproveUsage = async (Amount) => {
    try {
      const account = getWalletAddress()
      let result = await CheckApproveUsage(getYeildAddress(), Amount, account)
      if (result) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log(err, 'approveUsage__err')
      return false
    }
  }

  const approveUsage = async (Amount) => {
    try {
      const account = getWalletAddress()
      let result = await ApproveUsage(getYeildAddress(), Amount, account)
      if (result) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log(err, 'approveUsage__err')
      return false
    }
  }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={applyallocationmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Apply all allocations</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={() => { props.onHide() }} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              {/* <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>DSP-BNB</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{allocateDetails.toke}</p>
                </div>


              </div> */}


              {/* <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p> */}
              {/* <p className={`${cascading.tokenvaluedesc} mt-3`}>Deposit more into this spNFT to increase your yield.</p> */}





              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Summary
              </p>










              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Current total allocation</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>{currAllocate} PZLX</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>New total allocation</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>{roundToSignificant(newAllocate,6)} PZLX</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Updated positions</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>{allocateDetails.length}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deallocation fee</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>0 PZLX</p>
                </div>
              </div>



              <div className={`${cascading.warning_border} mt-4`}>
                <div className='d-flex align-items-start justify-content-start'>
                  <FaInfoCircle className={`${cascading.themeicon}`} />
                  <span className={`${cascading.label}`}>All of your PZLX allocations changes will be applied through consecutive transactions (one per updated position).</span>
                </div>

              </div>
            </div>





            <div className='mt-4'>
              {
                loader ?
                  <button className={`btn ${cascading.connectwalletbtn}`} type='button' >Loading...</button> :
                  <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { handleSumbit() }}>Apply allocations</button>
              }

              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
            </div>



            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>

</div> */}







          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

