import { getAddress } from "../hooks/useTokens"


//demo for PZL
export const POOLS = [
  {
    sousId: 1,
    StakingToken: {

      //bsc
      56: '', //M
      97: '0x9e4B393b81B930D37a7B52113F8D1F3054322862', //T

      //Eth
      1: '', //M
      11155111: '', //T

      //Avax
      43114: '', //M
      43113: '', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T

    },

    EarningToken: {

      //bsc
      56: '', //M
      97: '0x9e4B393b81B930D37a7B52113F8D1F3054322862', //T

      //Eth
      1: '', //M
      11155111: '', //T

      //Avax
      43114: '', //M
      43113: '', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T

    },
    // poolCategory: PoolCategory.CORE,
    contractAddress: {

      //bsc
      56: '', //M
      97: '0x81bf3B8D40d288E669B42e8F3A15Dc36C67783D7', //T

      //Eth
      1: '', //M
      11155111: '', //T

      //Avax
      43114: '', //M
      43113: '', //T

      //Arbitrum
      42161: '', //M
      421614: '', //T

      //zkSync
      324: '', //M
      280: '', //T

    },
    harvest: true,
    tokenPerBlock: '0.001',
    sortOrder: 0,
    isFinished: false,
  }

]


//demo for DSP

// export const POOLS = [
//   {
//     sousId: 1,
//     StakingToken: {

//       //bsc
//       56: '', //M
//       97: '0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4', //T

//       //Eth
//       1: '', //M
//       11155111: '', //T

//       //Avax
//       43114: '', //M
//       43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

//       //Arbitrum
//       42161: '', //M
//       421614: '', //T

//       //zkSync
//       324: '', //M
//       280: '', //T

//     },

//     EarningToken: {

//       //bsc
//       56: '', //M
//       97: '0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4', //T

//       //Eth
//       1: '', //M
//       11155111: '', //T

//       //Avax
//       43114: '', //M
//       43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

//       //Arbitrum
//       42161: '', //M
//       421614: '', //T

//       //zkSync
//       324: '', //M
//       280: '', //T

//     },
//     // poolCategory: PoolCategory.CORE,
//     contractAddress: {

//       //bsc
//       56: '', //M
//       97: '0x34AC91e49b93A710cE118e92B54B114798353cC6', //T

//       //Eth
//       1: '', //M
//       11155111: '', //T

//       //Avax
//       43114: '', //M
//       43113: '0x740f9a02544Cf6bdFdB3454C904A4eeEa32042Cd', //T

//       //Arbitrum
//       42161: '', //M
//       421614: '', //T

//       //zkSync
//       324: '', //M
//       280: '', //T

//     },
//     harvest: true,
//     tokenPerBlock: '0.001',
//     sortOrder: 0,
//     isFinished: false,
//   }

// ]

export const getPoolChain = () => {
  try {
    let pools = JSON.stringify(POOLS)
    pools = JSON.parse(pools)
    for (let i = 0; i < pools.length; i++) {
      pools[i]['earningToken'] = getAddress(pools[i].EarningToken)
      pools[i]['stakingToken'] = getAddress(pools[i].StakingToken)
      if (pools.length - 1 == i) {
        console.log(pools, 'getFarmsChain')
        return pools
      }
    }
  } catch (err) {
    console.log(err, "getPoolChain__err")
  }
}