import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/all.module.css';
import Addsuccessmodaldivident from '../modals/addsuccessmodaldivident';

import { Button, ButtonGroup } from 'react-bootstrap';
import { FaTrophy } from "react-icons/fa";

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';

//modals
import { useSelector } from 'react-redux';
import { HarvestAllDividends, HarvestDividends } from '../hooks/useDividends';
import { getWalletAddress } from '../lib/localStorage';

//lib
import fileObjectUrl from '../lib/img'
import { roundToSignificant } from '../hooks/useCommon';

import DividendsSuccessmodal from '../modals/DividendsSuccessmodal'

export default function Yourdividents(props) {

    const [modalsucessdivident, setmodalsuccessdivident] = useState(false);

    const { userInfo, distributeInfo } = useSelector((state) => (state.dividends))

    const [totalReward, SettotalReward] = useState('')

    useEffect(() => {
        let totalReward = userInfo?.reduce((total, currentValue) => {
            return total = parseFloat(total) + parseFloat(currentValue.pendingDividends)
        }, 0)
        SettotalReward(totalReward)
    }, [userInfo])

    //submit 
    const harvestAllDividends = async () => {
        try {
            let loaderText = `Harvesting all dividends.....`
            let title = `Harverset All`
            showsuccesModal(loaderText, title)
            let result = await HarvestAllDividends(getWalletAddress())
            if (result) {
                console.log(result, 'harvestDividends')
                let successText = `Successfully Harvested all dividends`
                SetsuccessLoader(successText, result.transactionHash)
            } else {
                console.log(result, 'harvestDividends')
                let errorText = `Error, When trying harvest`
                setError(errorText)
            }
        } catch (err) {
            console.log(err, 'harvestAllDividends__err')
        }
    }

    const harvestDividends = async (tokenAddress) => {
        try {
            let loaderText = `Harvesting dividends.....`
            let title = `Harverset`
            showsuccesModal(loaderText, title)
            let result = await HarvestDividends(getWalletAddress(), tokenAddress)
            if (result) {
                console.log(result, 'harvestDividends')
                let successText = `Successfully Harvested dividends`
                SetsuccessLoader(successText, result.transactionHash)
            } else {
                console.log(result, 'harvestDividends')
                let errorText = `Error, When trying harvest`
                setError(errorText)
            }
        } catch (err) {
            console.log(err, 'harvestDividends__err')
        }
    }
    const [title, settitle] = useState('')
    const [successLoader, setsuccessLoader] = useState(false)
    const [loaderText, setloaderText] = useState('')
    const [successText, setsuccessText] = useState('')
    const [errorText, seterrorText] = useState('')
    const [Trxn, setTrxn] = useState('')
    const [modalsucess, setModalsucess] = useState(false);

    const showsuccesModal = (text, title) => {
        try {
            settitle(title)
            setloaderText(text)
            setsuccessLoader(false)
            setmodalsuccessdivident(true);
        } catch (err) {
            console.log(err, 'showsuccesModal___err')
        }
    }

    const SetsuccessLoader = (text, hash) => {
        setTrxn(hash)
        setsuccessText(text);
        setsuccessLoader(true)
    }

    const setError = (text) => {
        seterrorText(text);
        setsuccessLoader(undefined)
    }
    return (
        <div className={`mb-4`}>

            <div className={`${cascading.innercard} ${cascading.dashcard}`}>
                <p className={`${cascading.head} ${cascading.headfelx} ${cascading.headflexnewwhite}`}>
                    {/* Your Dividends */}
                    Pending Rewards
                    <div>
                        <button
                            className={`btn me-2 ${cascading.stakebtnicon}`}
                            disabled={totalReward > 0 ? false : true}
                            onClick={() => {
                                harvestAllDividends()
                                setmodalsuccessdivident(true);
                            }}
                        >
                            Claim All
                        </button>
                    </div>
                </p>
                {/* <p className={`${cascading.desc}`}>
                    Earn real yield weekly from protocol earnings with PZLX.
                </p> */}


                {distributeInfo && userInfo && userInfo.length > 0 ?
                    userInfo.map((val, i) => {
                        let dividendInfo = distributeInfo[i]
                        console.log(dividendInfo, 'yourdividends')
                        return (
                            <>
                                <p className={`${cascading.headfelx} mt-4`}>
                                    <div className='d-flex align-items-center'>
                                        <div className={`${cascading.leftimgsec}`}>
                                            {
                                                dividendInfo?.isLp ?
                                                    <>
                                                        <img src={dividendInfo?.token1?.image ? fileObjectUrl(dividendInfo?.token1?.image) : bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                                        <img src={dividendInfo?.token2?.image ? fileObjectUrl(dividendInfo?.token2?.image) : coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                                                    </>
                                                    :

                                                    <img src={dividendInfo?.token?.image ? fileObjectUrl(dividendInfo?.token?.image) : bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                            }

                                        </div>
                                        <div>
                                            <p className={`${cascading.coinnamewhitenew} mb-0`}>{dividendInfo?.symbol}</p>
                                            <p className={`${cascading.coinnamewhitenew} mb-0`}>
                                                {roundToSignificant(val?.pendingDividends, 6)} <span className={`${cascading.coinnamegreynew}`}>({val.notinusd ? '' :'$'}{roundToSignificant(val.pendingDividendsinusd, 6)})</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className={`btn me-2 ${cascading.stakebtnicon}`}
                                            disabled={val?.pendingDividends > 0 ? false : true}
                                            onClick={() => {
                                                harvestDividends(dividendInfo?.tokenAddress)
                                            }}
                                        >
                                            Claim
                                        </button>
                                    </div>

                                </p>
                            </>
                        )
                    })
                    : <p className='text-white text-center'>No records found</p>}
            </div>


            {/* {modalsucessdivident && <Addsuccessmodaldivident onHide={() => setmodalsuccessdivident(false)} />} */}
            {modalsucessdivident && <DividendsSuccessmodal
                show={modalsucessdivident}
                title={title}
                loaderText={loaderText}
                successText={successText}
                isLoader={successLoader}
                // lpSymbol={viewrecord.lpsymbol}
                hash={Trxn}
                errorText={errorText}
                onHide={() => { setmodalsuccessdivident(false) }}
            />}
        </div>
    )
}
