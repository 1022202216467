import { CURRENT_CHAIN_ID, UseWeb3 ,useWeb3} from "./useWeb3"
import BigNumber from "bignumber.js"

import NFTfactoryABI from '../config/abi/NFTfactoty.json'
import contractAddress from '../config/constant/contract'

//lib
import { consolelog } from "../lib/consolelog"
import { getWalletAddress } from "../lib/localStorage"

//hooks
import { EstGas } from "./useCommon"
import { multicall } from "./useMultiCall"
import { toFixedNumber } from "../lib/toFixedOf"
import { ZEROTH_ADDRESS } from "../config/env"

export const getNFTFactoryAddress  =()=>{
    try{
        const CHAINID =  CURRENT_CHAIN_ID()
        let PoolFactoryAddress = contractAddress.NFTPoolFactory[`${CHAINID}`]
        return PoolFactoryAddress
    }catch(err){
        consolelog('getNFTFactoryAddress__err',err,true)
    }
}

export const UsePoolFactorycontract = async()=>{
    const web3 = await useWeb3()
    try{
        let PoolFactoryAddress = getNFTFactoryAddress()
        let contract =  new web3.eth.Contract(NFTfactoryABI,PoolFactoryAddress)
        return contract
    }catch(err){
        consolelog('Xcontract__err',err,true)
        return false
    }
}

export const GetPool = async(lpaddress)=>{
    const web3 = await useWeb3()
    try{
        let contract =  await UsePoolFactorycontract()
        let poolAddress =  await contract.methods.getPool(web3.utils.toChecksumAddress(lpaddress)).call()
        return poolAddress
    }catch(err){
        console.log(err,'getPool__Err')
        return ZEROTH_ADDRESS
    }
}

export const GetPoolLength = async()=>{
    try{
        console.log('GetPoolLength')
        let contract =  await UsePoolFactorycontract()
        let poolsLength =  await contract.methods.poolsLength().call()
        console.log(poolsLength,'poolsLength')
        return poolsLength
    }catch(err){
        console.log(err,'getPool__Err')
        return 0
    }
}

export const GetPoolAddress = async(index)=>{
    try{
        let contract =  await UsePoolFactorycontract()
        let poolAddress =  await contract.methods.pools(index).call()
        return poolAddress
    }catch(err){
        console.log(err,'getPool__Err')
        return ZEROTH_ADDRESS
    }
}

export const CreatePool = async(lpaddress)=>{
    const web3 = await useWeb3()
    try{
        let contract =  await UsePoolFactorycontract()
        console.log(lpaddress,contract,'CreatePool_lpaddress')
        const account = getWalletAddress()
        let params = [web3.utils.toChecksumAddress(lpaddress)]
        const { gasLimit, gasPrice } = await EstGas(params, NFTfactoryABI, getNFTFactoryAddress(), 'createPool', account)
        console.log(gasLimit,gasPrice,'CreatePool_EstGas')
        let data =  await contract.methods.createPool(web3.utils.toChecksumAddress(lpaddress)).send({ from: getWalletAddress(), gasLimit: gasLimit, gasPrice: gasPrice })
        console.log(data,'CreatePool')
        if (data) {
            return data
        }else{
            return false
        }
        
    }catch(err){
        console.log(err,'getPool__Err')
        return false
    }
}