import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/confirmremovestyle.module.css';

import bnbimg from '../assests/images/bnb.png'

import fileObjectUrl from '../lib/img'


import { IoMdClose } from "react-icons/io";
import { FaDownLong } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa6';
import { roundToSignificant } from '../hooks/useCommon';

export default function ConfirmRemovemodal(props) {

  var propsdata = props?.supplyObj
  console.log("propsdata", propsdata)

  useEffect(() => {
    console.log("props", props?.supplyObj, props?.pairInfo)
  }, [])



  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>You will receive</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.swapsec}`}>

            <div className={`${cascading.rowslist}`}>
              <p>
                {/* 0.0001567890765678 */}
                {(roundToSignificant(propsdata?.out1, 6))}
              </p>
              <p><img src={
                fileObjectUrl(propsdata?.fromToken?.image)
              } className={`me-2 ${cascading.coinimg}`} />{propsdata?.fromToken?.symbol}</p>
            </div>
            <div className={`my-3`}>
              <FaPlus fill='#fff' />
            </div>
            <div className={`${cascading.rowslist}`}>
              <p>
                {roundToSignificant(propsdata?.out2, 6)}
              </p>
              <p><img src={
                // bnbimg
                fileObjectUrl(propsdata?.toToken?.image)
              } className={`me-2 ${cascading.coinimg}`} />{propsdata?.fromToken?.symbol}</p>
            </div>


          </div>
          <p className={`${cascading.desccontent}`}>Output is estimated. If the price changes by more than 0.5% your transaction will revert.</p>

          <div className={`${cascading.listcard}`}>
            <ul>
              <li>
                <p>{`${propsdata?.fromToken?.symbol}/${propsdata?.toToken?.symbol}`}  Burned</p>
                <div>
                  <img src={
                    // bnbimg
                    fileObjectUrl(propsdata?.fromToken?.image)
                  } className={`${cascading.coinimg} ${cascading.coinimgstyle}`} />
                  <img src={
                    // bnbimg
                    fileObjectUrl(propsdata?.toToken?.image)
                  } className={`ms-1 ${cascading.coinimg} ${cascading.coinimgstyle}`} />
                  <span className={`${cascading.coinvaluespan} text-white ms-1`}>
                    {/* 0.00015676786050 */}
                    {roundToSignificant(propsdata?.lpValue, 6)}
                  </span>
                </div>

              </li>
              {/* <li>
        <p>MInimum received</p>
        <p>0.000 WBNB</p>
      </li> */}
              <li>
                <p>Price</p>
                <div>
                  <p className='text-center text-sm-end'>1{propsdata?.fromToken?.symbol} = {roundToSignificant(propsdata?.toTokenPer, 6)} {propsdata?.toToken?.symbol}</p>
                  <p className='text-center text-sm-end'>1 {propsdata?.toToken?.symbol} = {roundToSignificant(propsdata?.fromTokenPer, 6)} {propsdata?.fromToken?.symbol}</p>
                </div>
              </li>

              {/* <li>
        <p>Liquidity Provider Fee</p>
        <p>0.000 PZLX</p>
      </li> */}
            </ul>

          </div>


          <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { props?.supplyData() }} >Confirm</button>
          {/* <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button> */}






        </Modal.Body>

      </Modal>
    </div>
  )
}
