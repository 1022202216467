// import constant
import {
    POSITION_LIST,
    POSITION_RECORD,
    TOTAL_HOLDING,
    SPNFT_Reward,
    IS_POSITION,
    IS_LOAD_POSITION
} from '../constants';

const initialValue = {
    positionList: [],
    positionrecord: {},
    totalholding: 0,
    spNFTReward: 0,
    isposition: '',
    isload: true
}

//  console.log("initialValue----->>>",initialValue);
const positionReducer = (state = initialValue, action) => {

    switch (action.type) {
        case POSITION_LIST:
            return {
                ...state,
                positionList: action.payload
            };
        case POSITION_RECORD:
            return {
                ...state,
                positionrecord: action.payload
            };
        case TOTAL_HOLDING: {
            return {
                ...state,
                totalholding: action.payload
            };
        }
        case SPNFT_Reward: {
            return {
                ...state,
                spNFTReward: action.payload
            };
        }
        case IS_POSITION: 
        return {
            ...state,
            isposition: action.payload
        };
        // case IS_LOAD_POSITION:
        //     return {
        //         ...state,
        //         isload: action.payload
        //     };
        default:
            return state;
    }
}

export default positionReducer;