import { getAddress, getTokens } from "../hooks/useTokens"

//pid 3  is the COIN-BUSD pair used for usd values

//demo farms for DSP
// export const FARMS = [
//     {
//         pid: 1,
//         LpAddress: {
//             //bsc
//             56: '', //M
//             97: '0xeF91b900d0928a0eCe1498F58c91988F86652763', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0x06f2195486e3F67F321F2C43f6F6f05a419A5c5F', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         lpSymbol: "BNB-DSP LP",
//         tokenSymbol: "BNB",
//         TokenAddress: {
//             //bsc
//             56: '', //M
//             97: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         quoteTokenSymbol: "DSP",
//         QuoteTokenAddress: {
//             //bsc
//             56: '', //M
//             97: '0xEcEb014048E59398eC5BAfE5bc9361AD66C8f218',//'0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         isTokenOnly: false,
//         RewardToken: {
//             //bsc
//             56: '', //M
//             97: '0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         }
//     },
//     {
//         pid: 2,
//         LpAddress: {
//             //bsc
//             56: '', //M
//             97: '0x66D3452f1397ae7eba4323f903712b663DcFc7b0', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0xbEeb2BBe8883c6eDB37efCd077a46DA20437fC1C', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         lpSymbol: "DSP-BUSD LP",
//         tokenSymbol: "DSP",
//         TokenAddress: {
//             //bsc
//             56: '', //M
//             97: '0xEcEb014048E59398eC5BAfE5bc9361AD66C8f218', //'0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         quoteTokenSymbol: "BUSD",
//         QuoteTokenAddress: {
//             //bsc
//             56: '', //M
//             97: '0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0xc2DC6f4f0a87F2CA78cB0c5b6B3C0B9A5f7be9c6', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         isTokenOnly: false,
//         RewardToken: {

//             //bsc
//             56: '', //M
//             97: '0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         }
//     },
//     {
//         pid: 3,
//         LpAddress: {
//             //bsc
//             56: '', //M
//             97: '0x03Ee1C82e3f005E295963A38Ae555804728543a4', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0x8C32aAFee94b4568E029bcfd58390dFf5C70D157', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         lpSymbol: "BNB-BUSD LP",
//         tokenSymbol: "BNB",
//         TokenAddress: {
//             //bsc
//             56: '', //M
//             97: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         quoteTokenSymbol: "BUSD",
//         QuoteTokenAddress: {
//             //bsc
//             56: '', //M
//             97: '0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0xc2DC6f4f0a87F2CA78cB0c5b6B3C0B9A5f7be9c6', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         },
//         isTokenOnly: false,
//         RewardToken: {
//             //bsc
//             56: '', //M
//             97: '0x6705f12e33896259Dbd9298e1E39CC5AB1BdfEE4', //T

//             //Eth
//             1: '', //M
//             11155111: '', //T

//             //Avax
//             43114: '', //M
//             43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

//             //Arbitrum
//             42161: '', //M
//             421614: '', //T

//             //zkSync
//             324: '', //M
//             280: '', //T
//         }
//     }

// ]

// demo farms for PZL

export const FARMS = [
    {
        pid: 1,
        LpAddress: {
            //bsc
            56: '', //M
            97: '0x9856Fa626E4BCcE3A8d876DC63EE9B411BC5ea35', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        lpSymbol: "BNB-PZL LP",
        tokenSymbol: "BNB",
        TokenAddress: {
            //bsc
            56: '', //M
            97: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        quoteTokenSymbol: "PZL",
        QuoteTokenAddress: {
            //bsc
            56: '', //M
            97: '0x9e4B393b81B930D37a7B52113F8D1F3054322862', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        isTokenOnly: false,
        RewardToken: {
            //bsc
            56: '', //M
            97: '0x9e4B393b81B930D37a7B52113F8D1F3054322862', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        }
    },
    {
        pid: 2,
        LpAddress: {
            //bsc
            56: '', //M
            97: '0x7402909CB94F71BfC35E17F328b33E99F002Ed17', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        lpSymbol: "PZL-BUSD LP",
        tokenSymbol: "PZL",
        TokenAddress: {
            //bsc
            56: '', //M
            97: '0x9e4B393b81B930D37a7B52113F8D1F3054322862', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        quoteTokenSymbol: "BUSD",
        QuoteTokenAddress: {
            //bsc
            56: '', //M
            97: '0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        isTokenOnly: false,
        RewardToken: {

            //bsc
            56: '', //M
            97: '0x9e4B393b81B930D37a7B52113F8D1F3054322862', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        }
    },
    {
        pid: 3,
        LpAddress: {
            //bsc
            56: '', //M
            97: '0xEdEf7FfcAd91a5feA1850e95D2d6113FABd32E35', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        lpSymbol: "BNB-BUSD LP",
        tokenSymbol: "BNB",
        TokenAddress: {
            //bsc
            56: '', //M
            97: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        quoteTokenSymbol: "BUSD",
        QuoteTokenAddress: {
            //bsc
            56: '', //M
            97: '0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        isTokenOnly: false,
        RewardToken: {
            //bsc
            56: '', //M
            97: '0x9e4B393b81B930D37a7B52113F8D1F3054322862', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        }
    }

]

export const getFarmsChain = () =>{
    try{
        let farms  =  JSON.stringify(FARMS) 
        farms = JSON.parse(farms)
        for(let i=0 ;i<farms.length ;i++){
            farms[i]['lpAddress'] = getAddress(farms[i].LpAddress)
            let tokenAddress = getAddress(farms[i].TokenAddress)
            farms[i]['tokenAddress'] = tokenAddress
            farms[i]['tokenSymbol'] = getTokens().find((val)=>(val.address.toLowerCase() == tokenAddress.toLowerCase())).symbol
            let quoteTokenAddress =getAddress(farms[i].QuoteTokenAddress)
            farms[i]['quoteTokenAddress'] = quoteTokenAddress
            farms[i]['quoteTokenSymbol'] = getTokens().find((val)=>(val.address.toLowerCase() == quoteTokenAddress.toLowerCase())).symbol
            farms[i]['lpSymbol'] = `${farms[i]['tokenSymbol']}-${farms[i]['quoteTokenSymbol']} LP`
            farms[i]['rewardToken'] = getAddress(farms[i].RewardToken)
            if(farms.length-1 == i){
                console.log(farms,'getFarmsChain')
                return farms
            }
        }
    }catch(err){
        console.log('getFarmsChain__err',err)
    }
}

// export const FARMS = [
//     {
//         pid: 1,
//         lpAddress: '0x1F89c000F7a35c4eaba4D1FE43A34Fc349fC4be9',
//         lpSymbol: "BNB-DT LP",
//         tokenSymbol: "BNB",
//         tokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
//         quoteTokenSymbol: "DT",
//         quoteTokenAddress: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
//         isTokenOnly: false,
//         rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
//     },
//     {
//         pid: 2,
//         lpAddress: "0x4be3cde58edECaacCbDb280B12593bB5A9BFc6Db",
//         lpSymbol: "DT-BUSD LP",
//         tokenSymbol: "DT",
//         tokenAddress: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
//         quoteTokenSymbol: "BUSD",
//         quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
//         isTokenOnly: false,
//         rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
//     },
//     {
//         pid: 3,
//         lpAddress: '0x280041A60FbCE8eB3032F1c2Ef75654F1160D0D3', 
//         lpSymbol: "BNB-BUSD LP",
//         tokenSymbol: "BNB",
//         tokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
//         quoteTokenSymbol: "BUSD",
//         quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
//         isTokenOnly: false,
//         rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
//     }

// ]


    // {
    //     pid: 1,
    //     lpSymbol: 'DOUB-BNB LP',
    //     lpAddress: '0x56cf2Ea3921A353c6DEa3cBDde1BA5Ef10406DCB',
    //     tokenSymbol: "DOUB",
    //     quoteTokenSymbol: "WBNB",
    //     tokenAddress: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
    //     quoteTokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"
    // },
    // {
    //     pid: 2,
    //     lpSymbol: 'DOUB-BUSD LP',
    //     lpAddress: '0x8e0793d00c4b08A7F4501D273CDF4BBaDeDF3CF5',
    //     tokenSymbol: "DOUB",
    //     quoteTokenSymbol: "BUSD",
    //     tokenAddress: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
    //     quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"

    // },
    // {
    //     pid: 3,
    //     lpSymbol: 'BNB-BUSD LP',
    //     lpAddress: '0x74a4e192887d8FD0c3761f6ae6CCCfFa0fc65AA1',
    //     tokenSymbol: "WBNB",
    //     quoteTokenSymbol: "BUSD",
    //     tokenAddress: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c',
    //     quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"
    // }