import React, { useState, useEffect } from 'react';
import cascading from '../assests/css/landing.module.css';
import Footer from '../common/footer'
import Header from '../common/header';
import pretzelimg from "../assests/images/pretzelswapimg.png";
import donutoffice from "../assests/images/paraz_pretzel.png";

import swapdonut from "../assests/images/swap.png";
import liquiditydonut from "../assests/images/liquidity.png";
import poolsdonut from "../assests/images/pools.png";
import boosterdonut from "../assests/images/booster.png";
import cookdonut from "../assests/images/flightpretzel.png";

import trade1 from "../assests/images/Group 458.png";
import trade2 from "../assests/images/Group 459.png";
import trade3 from "../assests/images/Group 460.png";

import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { Link } from 'react-router-dom';

// import vector1 from "../assests/images/Vector 1.png";
// import vector2 from "../assests/images/Vector 2.png";

import partner1 from "../assests/images/partner1.png";
import partner2 from "../assests/images/partner2.png";
import partner3 from "../assests/images/partner3.png";
import partner4 from "../assests/images/partner4.png";
import partner5 from "../assests/images/partner5.png";
import partner6 from "../assests/images/partner6.png";
import partner7 from "../assests/images/partner7.png";
import partner8 from "../assests/images/partner8.png";

import blurimg from "../assests/images/blurdonut.png"

import Marquee from "react-fast-marquee";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Landingpage() {

    const [partner, setPartner] = useState([
        { "id": 1, "image": partner1 }, { "id": 2, "image": partner2, }, { "id": 3, "image": partner3 }, { "id": 4, "image": partner4, }, { "id": 5, "image": partner5,  }, { "id": 5, "image": partner6, "soon": "Coming Soon" }, { "id": 5, "image": partner7, "soon": "Coming Soon" }, { "id": 5, "image": partner8, "soon": "Coming Soon" }
    ]);

    useEffect(() => {
        AOS.init({
        });
    }, []);

    return (
        <>
            <Header />


            <div className={`container pt-5 pb-5 ${cascading.containmax}`}>

                <div className={`row pt-0 pt-sm-3 pb-5 align-items-center ${cascading.rowmin}`}>
                    <div className={`col-md-7 mt-5 mt-md-3 ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolent2}`}></span>
                        {/* <h1 className={`mb-0 ${cascading.welcome} ${cascading.heading1}`}>DeFi the standard</h1>    */}
                        <h1 className={`mb-0 ${cascading.welcome} ${cascading.heading1} ${cascading.mainhead}`}>
                            {/* Welcome To <span className={`${cascading.donutback}`}>PretzelSwap</span> */}
                            DeFi<br/>the <span className={`${cascading.themetxt}`}>standard</span>
                        </h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>PretzelSwap leads the way in pioneering solutions for liquidity providers and traders seeking a competitive edge. Launch the app today and embark on your trading adventure.</p>
                        <div className={`img-fluid mt-4 ${cascading.socialsection}`}>
                            <Link to="https://twitter.com/" >
                                <img src={media1} className={`img-fluid ${cascading.mediass}`} />
                                <img src={mediahover1} className={`img-fluid ${cascading.mediasshover}`} />
                            </Link>
                            <Link to="https://telegram.org/" >
                                <img src={media2} className={`img-fluid ${cascading.mediass}`} />
                                <img src={mediahover2} className={`img-fluid ${cascading.mediasshover}`} />
                            </Link>
                            <Link to="" >
                                <img src={media3} className={`img-fluid ${cascading.mediass}`} />
                                <img src={mediahover3} className={`img-fluid ${cascading.mediasshover}`} />
                            </Link>
                            <Link  to="https://github.com/" target='_blank'> 
                        <img src={media4} className={`img-fluid ${cascading.mediass}`}/>
                          <img src={mediahover4} className={`img-fluid ${cascading.mediasshover}`}/>
                         </Link>
                        </div>


                        {/* <button className={`btn mt-3 ${cascading.tradenow}`}>Trade Now</button> */}

                        {/* <Link to="https://pretzelswap.maticz.in/swap"><button className={`btn mt-3 ${cascading.colorbtn}`} type='button'>Swap Now</button></Link>
                        <Link to="https://pretzelswap.maticz.in/swap"><button className={`btn mt-3 ms-2 ms-md-3 ${cascading.learnbtn}`} type='button'>Learn More</button></Link> */}
               
                    </div>
                    <div className={`col-md-5 mt-3 ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolent}`}></span>
                        {/* <img src={blurimg} alt='img' className={`img-fluid ${cascading.blur1}`} /> */}
                        <img src={pretzelimg} alt='img' className={`img-fluid mt-4 mt-md-0`} />
                    </div>
                </div>

                <div className={`text-center pt-0 pt-lg-5 pb-4 ${cascading.posrel}`}>
                    <span className={`${cascading.blurviolent}`}></span>
                    <span className={`${cascading.blurviolent1}`}></span>
                    {/* <h2 className={`mb-0 ${cascading.welcome} ${cascading.heading} ${cascading.heading1}`}>We Are The Pretzelswap</h2>
                    <p className={`mt-2 mb-0 ${cascading.welcomepara} ${cascading.heading}`} >A Smarter Approach to DeFi <br></br>Experience our innovative, reliable, and dynamic DeFi platform that assures consistent investment returns.
                    </p> */}
                    <div className={`row pt-4 align-items-center ${cascading.rowmin}`}>
                    <div className={`col-lg-12`}>
                        {/* <h2 className={`mb-0 ${cascading.welcome}`}>We Are The Pretzelswap</h2> */}
                        <h2 className={`mb-0 ${cascading.welcome}`}>A Smarter Approach <span className={`${cascading.themetxt}`}>to DeFi.</span></h2>
                    <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.heading}`} >Experience our innovative, reliable, and dynamic DeFi platform that assures consistent investment returns.</p>
                    {/* <Link to="https://pretzelswap.maticz.in/swap"> <button className={`btn mt-4 ${cascading.colorbtn}`} type='button'>Swap Now</button></Link> */}
                            </div>
                        <div className={`col-lg-12 mt-4`}>
                        <div className={`row justify-content-center`}>
                        {/* <div className={`col-sm-6 col-lg-6 mt-4`}>
                            <img src={swapdonut} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess}`}>Swap</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>A Smarter Approach to DeFi Experience our innovative, reliable, and dynamic DeFi platform that assures consistent investment returns.
                            </p>
                        </div> */}
                        <div className={`col-sm-6 col-lg-4 mt-4`}>
                        <div className={`${cascading.provideborder}`}>
                            {/* <img src={liquiditydonut} alt='img' className={`img-fluid ${cascading.donutexp}`} /> */}
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess} text-start`}>Liquidity Provision </h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.cardtext}`}>Elevate your digital asset portfolio by becoming a liquidity provider. Choose your desired liquidity pool, allocate your assets, and begin accumulating a portion of the trading fees as you aid in enhancing market liquidity and stability.
                            </p>
                            </div>
                        </div>
                        <div className={`col-sm-6 col-lg-4 mt-4`}>
                        <div className={`${cascading.provideborder}`}>
                            {/* <img src={poolsdonut} alt='img' className={`img-fluid ${cascading.donutexp}`} /> */}
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess} text-start`}>Staking Rewards </h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.cardtext}`}>Maximize your cryptocurrency's potential with our user-friendly staking platform. See your investments grow through attractive rewards while you play a part in fortifying network security and balance.
                            </p>
                            </div>
                        </div>
                        <div className={`col-sm-6 col-lg-4 mt-4`}>
                        <div className={`${cascading.provideborder}`}>
                            {/* <img src={boosterdonut} alt='img' className={`img-fluid ${cascading.donutexp}`} /> */}
                            <h5 className={`mt-2 mb-0 ${cascading.welcomess} text-start`}>Yield Booster</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara} ${cascading.cardtext}`}>Embark on a journey to substantial gains with our High Yield Platform. Delve into a range of high-return possibilities tailored to various risk appetites. Boost your income and elevate your investing prowess.
                            </p>
                            </div>
                        </div>
                        </div>
                        </div>
                       
                    </div>
                </div>
                <div className='pt-4'>

                <div className={`row pt-5 pb-5 align-items-center ${cascading.rowmin}`}>
                    <div className={`col-md-7 mt-3 ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolent2}`}></span>
                        <h1 className={`mb-0 ${cascading.welcome}`}>Trade Across Multiple Chains <span className={`${cascading.themetxt}`}>with Ease</span></h1>
                        <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Dive into an effortless trading experience with PretzelSwap, where the boundaries of different blockchains vanish. Our versatile multi-chain platform enables you to trade an extensive range of assets across various networks, offering unparalleled convenience and effectiveness. Break free from constraints and welcome a world of limitless trading possibilities!</p>
                        {/* <button className={`btn mt-3 ${cascading.tradenow}`}>Trade Now</button> */}
                        <Link to="https://pretzelswap.maticz.in/swap"><button className={`btn mt-4 ${cascading.colorbtn}`} type='button'>Trade Now</button></Link>
                    </div>
                    <div className={`col-md-5 mt-3  ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolent2}`}></span>
                        <span className={`${cascading.blurviolent1}`}></span>
                        <img src={donutoffice} alt='img' className={`img-fluid mt-5 mt-md-0`} />
                    </div>
                </div>
                </div>
                {/* <div className={`text-center pt-5 ${cascading.posrel}`}>
                <span className={`${cascading.blurviolent}`}></span>
                    <h2 className={`mb-0 ${cascading.welcome} ${cascading.heading} ${cascading.heading1}`}>How Trading Work</h2>
                    <p className={`mt-2 mb-0 ${cascading.welcomepara} ${cascading.heading}`}>In publishing and graphic design, Lorem ipsum is a placeholder<br></br>
                        text commonly used to demonstrate the visual.
                    </p>
                      <div className={`row pt-4 pb-5 justify-content-center`}>
                        <div className={`col-sm-6 col-md-4 col-xl-4  mt-3 ${cascading.posrel}`}>
                            <img src={trade1} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            <img src={vector1} alt='img' className={`img-fluid ${cascading.donutexp1}`} />
                            <h5 className={`mt-2 mb-0 ${cascading.welcome}`}>Click The Buy Trade Button</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                            </p>
                        </div>
                        <div className={`col-sm-6 col-md-4 col-xl-4 mt-3 ${cascading.posrel}`}>
                            <img src={trade2} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            <img src={vector2} alt='img' className={`img-fluid ${cascading.donutexp2}`} />
                            <h5 className={`mt-2 mb-0 ${cascading.welcome}`}>Connect Your Metamask Wallet</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                            </p>
                        </div>
                        <div className={`col-sm-6 col-md-4 col-xl-4 mt-3 mb-sm-5 mb-0`}>
                            <img src={trade3} alt='img' className={`img-fluid ${cascading.donutexp}`} />
                            <h5 className={`mt-2 mb-0 ${cascading.welcome}`}>Execute Trade And Complete</h5>
                            <p className={`mt-3 mb-0 ${cascading.welcomepara}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                            </p>
                        </div>

                    </div>
                </div> */}


                 <div className={`text-center pt-5 pb-5`}>
                   <h2 className={`mb-0 ${cascading.welcome}`}>Network <span className={`${cascading.themetxt}`}>Integrations</span></h2>
                    <p className={`mt-2 mb-3 ${cascading.welcomepara} ${cascading.heading}`} >Our ecosystem is continually growing, and we have established numerous partnerships.</p>
                    {/* <Marquee speed={100} direction='left'>
                        <div className={`mb-3 mt-2 ${cascading.partnermarquee}`}>
                            {partner.map((e, i) =>
                                <>
                                    <div className={`mb-0 mt-2 ${cascading.partnerwidth}`}>
                                        <img src={e.image} className={`img-fluid me-2 ${cascading.marqueeimg1}`} alt="banner" />
                                        <h6 className={`mt-2 ${cascading.coming}`}>{e.soon}</h6>
                                    </div>

                                </>
                            )}

                        </div>
                    </Marquee> */}
                     <div className={`row justify-content-center ${cascading.rowmin}`}>
                    {partner.map((e, i) =>
                        <div className={`col-6 col-sm-4 col-md-3 mt-4 ${cascading.colwidth}`}>
                         <a href='/' target='_blank'><img src={e.image} id={`ingwidth_`+i} className={`img-fluid ${cascading.marqueeimg}`}  alt="banner" /></a>  
                        <h6 className={`mt-2 ${cascading.coming}`}>{e.soon}</h6>       
                        </div>
                    )}
                    </div>
                </div>

                <div className={`pt-0 pt-lg-5 ${cascading.rowmin} ${cascading.rowminpadding}`}>
                    <div className={`mt-3 mt-lg-5 mb-5 ${cascading.donutrose} ${cascading.posrel}`}>
                        <span className={`${cascading.blurviolentlast}`}></span>
                        <div className={`row ${cascading.rows}`}>
                            <div className={`col-lg-5 col-xl-6 col-md-6 mt-4 mt-md-0`}>
                                <h3 className={`mb-2 ${cascading.welcome}`}>Get Started With<br></br>
                                    Pretzelswap</h3>
                                <p className={`mb-0 ${cascading.welcome1}`}>Stay Up To Date With The Latest News. We
                                    Won’t Send Any Spam & Protect Privacy.</p>
                                <div className={`${cascading.maillist}`}>
                                    <input type="text" className={`form-control mt-3`} placeholder="Your Email" aria-label="Username" aria-describedby="basic-addon1" />
                                    <button className={`btn mt-3 ms-2 ms-lg-2 ${cascading.blackbtn}`}>Join!</button>
                                </div>

                            </div>
                            <div className={`col-md-5 col-lg-5 col-xl-5`}>
                                <img src={cookdonut} alt='img' className={`img-fluid`} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>







            <Footer />
        </>
    )
}
