import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/stakestyle.module.css';
import toast, { Toaster } from 'react-hot-toast';

import { useDispatch } from 'react-redux';

import { IoMdClose } from "react-icons/io";

import bnbimg from '../assests/images/bnb.png'

//hooks
import { Balance } from '../hooks/useErc20'
import { Harvest, Deposit } from '../hooks/useMasterChef'

//lib
import { isEmpty } from "../lib/isEmpty";
import { toFixedNumber } from '../lib/FixedNumber';

//validation
import { validateStakeFarms } from '../validation/stakeFarmsValidation'


export default function Stakemodal(props) {

  // state 
  const [stakemodal, setStakemodal] = useState(true);
  const [data, setData] = useState();
  const [bal, setBal] = useState();
  const [stakeValue, setStakeValue] = useState('');
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState("");


  useEffect(() => {
    console.log("stakemodal", props?.farms?.data, props)
    setData(props?.farms?.data)
    getBalance()
  })

  const getBalance = async () => {
    var bal = await Balance(data?.lpAddress)
    console.log("bal", bal)
    setBal(bal)
  }

  const Max = () => {
    setStakeValue(bal)
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name == "stakeValue") {
      var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (!numbers.test(e.target.value) && e.target.value !== "") {
        return false
      }
      setStakeValue(value)
    }
  };

  const staking = async () => {
    console.log("parseFloat(stakeValue*10**18).toFixed(0) : ", parseFloat(stakeValue * 10 ** 18).toFixed(0))
    let value = {
      stakeValue: toFixedNumber(stakeValue),
      bal: toFixedNumber(bal)
    }
    let validation = validateStakeFarms(value)
    console.log("validation", validation)
    if (isEmpty(validation)) {
      setPending(true)
      setErrors('');
      var res = await Deposit(data?.pid, toFixedNumber(parseFloat(stakeValue * 10 ** 18).toFixed(0)))
      console.log("res", res)
      if (res === true) {
        toast.success(`Your ${data?.lpSymbol} funds have been staked in the farms!`)
        props.onHide()
        setPending(false)
      }
      else {
        setPending(false)
        toast.error('Try Again!')
      }
    }
    else {
      setErrors(validation);
    }
  }


  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={stakemodal} onHide={props.onHide} className={`${cascading.modalstyle}`}>
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Stake LP tokens</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
            <div className={`${cascading.inputsec} mb-4`}>
              <div className={`${cascading.balancerow} ${cascading.balancerowcol}`}>
                <p className={`${cascading.inputlabel}`}>Balance :</p>
                <p className={`${cascading.inputlabel} ${cascading.inputlabelbreak}`}>{toFixedNumber(bal)}</p>
              </div>

              <div className={`${cascading.inputsecrow}`}>
                <div >
                  <input
                    autoComplete={"off"}
                    type="text"
                    className={`from-control`}
                    id="tokenAval"
                    aria-describedby="emailHelp"
                    placeholder="0.0"
                    value={isEmpty(stakeValue) && isNaN(stakeValue) ? 0.00 : toFixedNumber(stakeValue)}
                    name='stakeValue'
                    onChange={(e) => { onChange(e) }}
                  />
                </div>

                <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                  <button
                    className={`btn ${cascading.currencybtn}`} onClick={() => { Max() }}
                  > Max </button>

                  <p className={`${cascading.inputlabel} ms-2 mb-0`}>{data?.lpSymbol}</p>


                </div>



              </div>

            </div>
            <p className={`${cascading.dangertext}`}>{errors.stakeValue}</p>


            <div className='d-flex align-items-center justify-content-between'>


              <button className={`btn ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

              <button className={`btn ms-3 ${cascading.connectwalletbtn}`} type='button' onClick={() => { staking() }} disabled={stakeValue == 0}>{pending == true ? "Confirming..." : "Confirm"}</button>

            </div>
            {/* <div className='text-center mt-4'>
                                <button className={`${cascading.declabel}`} target="_blank" >Get DSP-BNB LP</button>

                                </div> */}




          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

