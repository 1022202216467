import React, { useEffect, useState } from 'react'
import cascading from '../assests/css/all.module.css';
import logo from '../assests/images/cardlogo.png'
import Walletmodal from '../modals/walletmodal';
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

import { useSelector } from 'react-redux';
import pools_icon from '../assests/images/pools_icon.png';

import ReactDatatable from '@ashvin27/react-datatable';


import { AiOutlineCalculator } from "react-icons/ai";
import { Link } from 'react-router-dom';
import Calculatormodal from '../modals/calculatormodal';
import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';
import { useNavigate } from 'react-router-dom';
import { roundToSignificant } from '../hooks/useCommon';

import fileObjectUrl from '../lib/img'
import { isEmpty } from '../lib/isEmpty';

export default function Yumpoollist(props) {
    const navigate = useNavigate()
    const {filter} =props
    const { nftpooldata,poolrewards,pooldeposits } = useSelector((state) => (state.nftpool))
    console.log(nftpooldata,'nftpooldata')
    const [Records,setRecords] = useState([])
    useEffect(()=>{
        if(filter == 'Staked_only'){
            StakedOnly([...nftpooldata])
        }else if(filter =='Incentives_only'){
            IncentivesOnly([...nftpooldata])
        }
        else{
            setRecords([...nftpooldata])
        }
    },[filter,nftpooldata])

    const StakedOnly = (records)=>{
        try{
           let Data =  records.filter((val,i)=>{
                return pooldeposits[`${val.poolAddress}`] > 0 ? true :false
            })
            setRecords(Data)
        }catch(err){
            console.log(err,'StakedOnly__err')
        }
    }

    const IncentivesOnly = (records)=>{
        try{
           let Data =  records.filter((val,i)=>{
                return parseFloat(val.allocPoint) > 0 ? true :false
            })
            setRecords(Data)
        }catch(err){
            console.log(err,'StakedOnly__err')
        }
    }

    const config = {
        button: {
            excel: false,
            print: false,
            extra: false,
        },
        // show_filter:false,
        show_length_menu: false,
        show_pagination: false
    }

    const columns = [
        {
            key: "lpsymbol",
            text: "Pool",
            className: "pool",
            align: "left",
            sortable: true,
            cell: record => {
                let tokenA = record.token0
                let tokenB = record.token1
                console.log(record,'Yumpoollist')
                return (
                    <>
                        <div className={`${cascading.imgsec}`}>
                            <div className={`${cascading.leftimgsec}`}>
                                <img src={isEmpty(tokenA) ? coinlogo : fileObjectUrl(tokenA.image)} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                                <img src={isEmpty(tokenB) ? coinlogo : fileObjectUrl(tokenB.image)} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                            </div>
                            <p className={`${cascading.coinname} mb-0`}>{record.token0.symbol}-{record.token1.symbol}</p>
                            {/* <Link to="/yumpooldetail"> */}
                            {parseFloat(record.allocPoint) > 0 ? <OverlayTrigger
                                    className={`${cascading.tooltip_style}`}
                                    key="bottom"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                            Incentivized with PZLX

                                        </Tooltip>
                                    }
                                >
                                    <Button className={`${cascading.tooltip_button} px-0`}>                                   
                                      <img src={pools_icon} className={`img-fluid ${cascading.balanceimg}`} /> 
                                    </Button>
                                </OverlayTrigger>
                                :''}
                            {/* </Link> */}

                        </div>

                    </>
                );
            }

        },
        {
            key: "lpSupplyInusd",
            text: "TVL",
            className: "tvl",
            align: "left",
            sortable: true,
            cell: records => {
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{records.notinusd ? '':'$'} {roundToSignificant(records.lpSupplyInusd, 6)}</p>
                        </div>
                    </>
                )

            }

        },
        {
            key: "aprrange",
            text: "APR Range",
            className: "aprrange",
            sortable: true,
            width: 80,
            cell: records => {
                return (
                    <div className='d-flex align-items-center'>
                        <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{roundToSignificant(records.Apr,6)}% 
                        {/* <FaAngleRight />
                        <span className={`${cascading.themeapr}`}>12.34%
                        </span> */}
                        </p>
                        <div>
                            <OverlayTrigger
                                className={`${cascading.tooltip_style}`}
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        <p className='mb-1'>Swap fees APR: {roundToSignificant(records.SwapFeeApr,6)}%%</p>
                                        <p className='mb-1'>Farm APR: {roundToSignificant(records.FramBaseApr,6)} %</p>
                                        {/* <p className='mb-0'>PZLX rewards: +3.14%</p> */}

                                    </Tooltip>
                                }
                            >
                                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    // <img src={tick} alt='tick' className={`img-fluid`} width={17} height={17}/>
                )

            }
        },
        {
            key: "deposits",
            text: "Your Deposits",
            className: "deposits",
            align: "left",
            sortable: true,
            cell: records => {
                let deposit =  pooldeposits[`${records.poolAddress}`]
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>{records.notinusd ? '':'$'} {roundToSignificant(deposit, 6)}</p>
                        </div>
                    </>
                )
            }
        },
        {
            key: "rewards",
            text: "Pending Rewards",
            className: "rewards",
            sortable: true,
            align: "left",
            cell: records => {
                let rewards =  poolrewards[`${records.poolAddress}`]
                return (
                    <>
                        <div className='d-flex align-items-center'>
                            <p className={`mb-0 d-flex align-items-center ${cascading.labelvalue} ${cascading.calculatorbtn}`}>$ {roundToSignificant(rewards, 6)}</p>
                        </div>
                    </>
                )
            }
        },


    ];

    const userdetail = useSelector((state) => state.admin)
    return (
        <>
            <div className={`${cascading.innerpages} ${cascading.launchpadpage} ${cascading.pooltable} ${cascading.pooltablewidth}`}>
                <div className={`${cascading.tabstyle} mt-3 table-responsive`} id='launchpadtable' >
                    <ReactDatatable
                        // data={data}
                        className={`${cascading.datatablestyle}`}
                        config={config}
                        columns={columns}
                        records={Records}
                        onRowClicked={(e,record) =>{ 
                            console.log(record,'record__record')
                            navigate(`/pzlxpooldetail/${record.poolAddress}`)
                        }}
                    />
                </div>
            </div>
        </>

    )
}
