import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/yourwalletstyle.module.css';
import { IoMdClose } from "react-icons/io";

import { useDispatch, useSelector } from 'react-redux';
import { FaRegCopy,FaExternalLinkAlt } from "react-icons/fa";
// import { walletControl } from '../Redux/action';
import { walletControl } from '../lib/localStorage';

import { useState } from 'react';
import { Link } from 'react-router-dom';
export default function Yourwallet(props) {
  const dispatch = useDispatch()
  const userdetail = useSelector((state) => state.admin)
  const [tabwallet, setTabwallet] = useState(props.statedata)
  const walletdis = () => {
    dispatch(walletControl('disconnect')) 
    localStorage.setItem('walletStatus','disconnect')

  }
  return (
    <div className={`${cascading.walletmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Your Wallet</h4>
        
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
        
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.tabsec}`}>
            <button onClick={() => setTabwallet(true)} className={tabwallet ? `btn ${cascading.tabbtn} ${cascading.active}` : `btn ${cascading.tabbtn}`}>Wallet</button>
            <button onClick={() => setTabwallet(false)} className={!tabwallet ? `btn ${cascading.tabbtn} ${cascading.active}` : `btn ${cascading.tabbtn}`}>Transactions</button>
          </div>
          <div className={`${cascading.tabinnersec}`}>
            {tabwallet ? <>
              <div className={`mb-4`}>
              <p className={`${cascading.label}`}>YOUR ADDRESS</p>
                <div className={`input-group mb-3 mt-3 ${cascading.copyinput}` }>
               
                  <input type="text" class="form-control" value={"0x025c1667471685c323808647299e5DbF9d6AdcC9"}placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                    <span class="input-group-text" id="basic-addon2"><FaRegCopy fill='#fff' /></span>
                </div>
                </div>
                <div className={`${cascading.balrow}`}>
                  <p>BNB Balance</p>
                  <div className={`${cascading.rightside}`}> <p>0.270559</p>
                  <Link to="">View on BscScan <FaExternalLinkAlt /></Link>
                  </div>
                 

                </div>



              </> :<>
              <p className={`${cascading.label}`}>RECENT TRANSACTIONS</p>
              <div className={`${cascading.norecent}`}>
                <p className={`${cascading.recentlabel}`}>No recent transactions</p>
              </div>
              
              
              </>
            }

            </div>

        </Modal.Body>
        {tabwallet && 
        <Modal.Footer className={`${cascading.modalclr} ${cascading.modalfooter}`}>

          <Button className={`${cascading.btnstyle}`} onClick={()=> {walletdis();props.onHide()}}>
            Disconnect Wallet
          </Button>

        </Modal.Footer>
        }
      </Modal>
    </div>
  )
}

