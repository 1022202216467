import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/addliquiditystyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegQuestionCircle, FaArrowRight, FaChevronLeft, FaCopy } from "react-icons/fa";
import { useSelector } from 'react-redux';

import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';




import { IoMdClose } from "react-icons/io";


//lib
import fileObjectUrl from '../lib/img';
import { Duration } from '../lib/DateTimeHelper';
import { ZEROTH_ADDRESS } from '../config/env';
import { consolelog } from '../lib/consolelog';

//hooks
import { GetMultiplierSettings, GetPoolInfo, GetBounsApr,GetMultiplierByLockDuration } from '../hooks/useNFTpool';
import { IsCurrency, roundToSignificant } from '../hooks/useCommon';
import { toFixedNumber } from '../lib/FixedNumber';
import { AddLiquidityAndCreatePosition, AddLiquidityETHAndCreatePosition } from '../hooks/usePositionHelper';
import { getWalletAddress } from '../lib/localStorage';
import { isEmpty } from '../lib/isEmpty';

export default function Addliquiditymodal(props) {

  const [liquiditymodal, setLiquiditymodal] = useState(true);
  const [MultiplierSettings, setMultiplierSettings] = useState({});
  const [Lockduration, setLockDuration] = useState(0)
  const [incDisable, setincDisable] = useState(false)
  const [DecDisable, setDecDisable] = useState(false)
  const [days, Setdays] = useState('0')
  const [months, Setmonths] = useState('0')
  const [lockPerc, setLockPerc] = useState('0')
  const [poolInfo, setPoolInfo] = useState({})
  const [lockBonusApr, setlockBonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const [multiper, setmultiper] = useState('0')
  const { data, SetsuccessLoader, showsucces, setError } = props

  const { deadline, slippage } = useSelector((state) => state.settings)
  const { nftpooldata } = useSelector((state) => (state.nftpool))

  useEffect(() => {
    CalculateBonusApr()
  }, [lockPerc,poolInfo])

  const CalculateBonusApr = ()=>{
    try{
      console.log(lockPerc,'lockPerc_useEffect')
      let BonusApr = GetBounsApr({
        annualInterestRate: poolInfo.FramBaseApr,
        bonusPercentage: lockPerc
      })
      console.log(BonusApr,'lockPerc_useEffect')
      BonusApr = isNaN(BonusApr) ? 0: BonusApr
      setlockBonusApr(BonusApr)
      let totalApr = parseFloat(BonusApr)+parseFloat(poolInfo.FramBaseApr)+parseFloat(poolInfo.SwapFeeApr)
      console.log(totalApr,'totalApr')
      settotalApr(totalApr)
    }catch(err){
      console.log('CalculateBonusApr__err')
    }
  }
  useEffect(()=>{
    SetMultiperval()
  },[Lockduration])

  const SetMultiperval = async()=>{
    try{
    let multiperVal =  await GetMultiplierByLockDuration(poolInfo.poolAddress,Lockduration)
    console.log(multiperVal,'SetMultiperval')
    setmultiper(multiperVal)
    }catch(err){
      console.log(err,'SetMulitplierval__Err')
    }
  }
  useEffect(() => {
    fetchMultiplierSettings()
  }, [data, nftpooldata])

  const fetchMultiplierSettings = async () => {
    let result = nftpooldata?.find((val) => val?.poolAddress == data.poolAddress)
    // console.log(result, 'fetchMultiplierSettings', data.poolAddress, nftpooldata)
    setPoolInfo(result)

    let MultiplierSettings = await GetMultiplierSettings(data.poolAddress)
    setMultiplierSettings(MultiplierSettings)
  }

  //change func
  const inCreaseDuration = () => {
    try {
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time + interval
      console.log(!(Time > MultiplierSettings.maxLockDuration), 'inCreaseDuration')
      if (!(Time > MultiplierSettings.maxLockDuration)) {
        if (Time == MultiplierSettings.maxLockDuration) {
          setincDisable(true)
        }
        let days = Duration(Time, 'days')
        let lockPercn = (Time / MultiplierSettings.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        days = days % 30
        if (days == 0) {
          Setmonths(parseFloat(months) + 1)
          Setdays(0)
        }
        else {
          Setdays(parseFloat(days))
        }
        setDecDisable(false)
        setLockDuration(Time)
      }

    } catch (err) {
      consolelog('inCreaseDuration__err', err, true)
    }
  }

  const DeCreaseDuration = () => {
    try {
      let Time = Lockduration
      let interval = 1 * 86400
      Time = Time - interval
      if (!(Time < 0)) {
        if (Time == 0) {
          setDecDisable(true)
        }
        let lockPercn = (Time / MultiplierSettings.maxLockDuration) * 100
        setLockPerc((lockPercn).toFixed(2))
        let Days = Duration(Lockduration, 'days')
        Days = Days % 30
        if (Days == 0) {
          Setmonths(parseFloat(months) - 1)
          Setdays(29)
        }
        else {
          Setdays(parseFloat(Days) - 1)
        }
        setincDisable(false)
        setLockDuration(Time)
      }
    } catch (err) {
      consolelog('DeCreaseDuration__err', err, true)
    }
  }

  const maxLockDuration = () => {
    try {
      let months = Duration(MultiplierSettings.maxLockDuration, 'months')
      let days = Duration(MultiplierSettings.maxLockDuration, 'days')
      console.log(months, days, 'maxLockDuration')
      let lockPercn = (MultiplierSettings.maxLockDuration / MultiplierSettings.maxLockDuration) * 100
      setLockPerc((lockPercn).toFixed(2))
      days = days % 30
      Setdays(days)
      Setmonths(months)
      setLockDuration(MultiplierSettings.maxLockDuration)
      setincDisable(true)
      setDecDisable(false)

    } catch (err) {
      consolelog('maxLockDuration__err', err, true)
    }
  }

  const SupplyAndCreatePosition = async () => {
    try {
      let tokenA = data.tokenA
      let tokenB = data.tokenB
      let tokenAval = data.tokenAval
      let tokenBval = data.tokenBval
      let isFromBNB = IsCurrency(tokenA)
      let isToBNB = IsCurrency(tokenB)
      if (IsCurrency(tokenA) || IsCurrency(tokenB)) {
        let token = isFromBNB ? tokenB?.address : tokenA?.address

        let slippageval = parseFloat(slippage) / 100
        let amountTokenDesired = isFromBNB ? toFixedNumber(tokenBval) : toFixedNumber(tokenAval)
        let amountTokenMin = parseFloat(amountTokenDesired) * (1 - slippageval);
        amountTokenDesired = isFromBNB ? amountTokenDesired * 10 ** tokenB?.decimal : amountTokenDesired * 10 ** tokenA?.decimal
        amountTokenDesired = toFixedNumber(parseFloat(amountTokenDesired).toFixed(0))

        amountTokenMin = isFromBNB ? amountTokenMin * 10 ** tokenB?.decimal : amountTokenMin * 10 ** tokenB?.decimal
        amountTokenMin = toFixedNumber(parseFloat(amountTokenMin).toFixed(0))

        let amountETHDesired = isFromBNB ? toFixedNumber(tokenAval) : toFixedNumber(tokenBval)
        let amountETHMin = parseFloat(amountETHDesired) * (1 - slippageval);
        amountETHDesired = toFixedNumber(parseFloat(amountETHDesired * 10 ** 18).toFixed(0))
        amountETHMin = amountETHMin * 10 ** 18
        amountETHMin = toFixedNumber(parseFloat(amountETHMin).toFixed(0))

        let deadLine = ((new Date().getTime() / 1000) + parseFloat(deadline * 60)).toFixed(0)

        let loaderText = 'Adding liquidity....'
        showsucces(loaderText)
        let result = await AddLiquidityETHAndCreatePosition(token, amountTokenDesired, amountTokenMin, amountETHMin, deadLine, getWalletAddress(), data.poolAddress, Lockduration, amountETHDesired)
        let successText = `You've successfully provided  ${roundToSignificant(data.addliquidity,6)} of ${tokenA.symbol}-${tokenB.symbol} liquidity`
        if (result) {
          SetsuccessLoader(successText, result.transactionHash)
        } else {
          let errorText = 'Error ! When AddLiquidity'
          setError(errorText)
        }

        console.log(result, 'AddLiquidityETHAndCreatePosition')
      }
      else {
        let slippageval = parseFloat(slippage) / 100
        console.log(tokenAval, tokenBval, slippageval, "slippage_slip")
        let amountAMin = parseFloat(tokenAval) * (1 - slippageval);
        amountAMin = amountAMin * 10 ** tokenA?.decimal
        amountAMin = toFixedNumber(parseFloat(amountAMin).toFixed(0))
        console.log("TokenAMin", amountAMin)

        let amountBMin = parseFloat(tokenBval) * (1 - slippageval);
        amountBMin = amountBMin * 10 ** tokenB?.decimal
        amountBMin = toFixedNumber(parseFloat(amountBMin).toFixed(0))
        console.log("TokenBMin", amountBMin)

        let amountADesired = parseFloat(tokenAval) * 10 ** tokenA?.decimal
        amountADesired = toFixedNumber(parseFloat(amountADesired).toFixed(0))

        let amountBDesired = parseFloat(tokenBval) * 10 ** tokenB?.decimal
        amountBDesired = toFixedNumber(parseFloat(amountBDesired).toFixed(0))

        let deadLine = ((new Date().getTime() / 1000) + parseFloat(deadline * 60)).toFixed(0)
        let loaderText = 'Adding liquidity....'
        showsucces(loaderText)
        let result = await AddLiquidityAndCreatePosition(tokenA?.address, tokenB?.address, amountADesired, amountBDesired, amountAMin, amountBMin, deadLine, getWalletAddress(), data.poolAddress, Lockduration)
        let successText = `You've successfully provided  ${data.addliquidity} of ${tokenA.symbol}-${tokenB.symbol} liquidity`
        if (result) {
          SetsuccessLoader(successText, result.transactionHash)
        } else {
          let errorText = 'Error ! When AddLiquidity'
          setError(errorText)
        }
        console.log(result, 'AddLiquidityAndCreatePosition')
      }
    } catch (err) {
      console.log(err, 'SupplyAndCreatePosition__err')
    }
  }
  // console.log(MultiplierSettings.maxLockDuration,'MultiplierSettings')
  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={liquiditymodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Add Liquidity</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.tokenrow}`}>
                <img src={fileObjectUrl(data.tokenA.image)} alt='coin' className={`img-fluid ${cascading.timg}`} />
                <div>
                  <p className={`${cascading.name}`}>{data.tokenA.symbol}</p>
                  <p className={`${cascading.desc}`}>{roundToSignificant(data.tokenAval, 6)}</p>
                </div>
              </div>
              <div className={`${cascading.tokenrow} ${cascading.arrowrow} my-3`}>
                <div className={`${cascading.swaparrow}`}>
                  <FaPlus />
                </div>
              </div>
              <div className={`${cascading.tokenrow}`}>
                <img src={fileObjectUrl(data.tokenB.image)} alt='coin' className={`img-fluid ${cascading.timg}`} />
                <div>
                  <p className={`${cascading.name}`}>{data.tokenB.symbol}</p>
                  <p className={`${cascading.desc}`}>{roundToSignificant(data.tokenBval, 6)}</p>
                </div>

              </div>

              <div className={`${cascading.createaccordion} mb-4 mt-4`}>

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey={0}>
                    <Accordion.Header>
                      <div className={`mb-0 text-start pt-1 ${cascading.labelflex}`}>
                        <p className={`mb-3 text-start ${cascading.label}`}>
                          1 {data.tokenA.symbol} = {roundToSignificant(data.toTokenper, 6)} {data.tokenB.symbol}
                          {/* <span className='ps-1'>($2.87)</span> */}
                        </p>
                        <p className={`mb-0 text-start ${cascading.label}`}>1 {data.tokenB.symbol} = {roundToSignificant(data.fromTokenper, 6)} {data.tokenA.symbol}
                          {/* <span className='ps-1'>($3,251.56)</span> */}
                        </p>

                      </div>
                    </Accordion.Header>

                    <Accordion.Body className={`pb-1`}>
                      <div className={``}>
                        <div className={`${cascading.valuecardsec} ${cascading.valuecardsecnpng} mb-4`}>
                          {/* <div className={`${cascading.secrow}`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pair Type

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>Volatile
                            </p>
                          </div> */}

                          {data.pairAddress !== ZEROTH_ADDRESS ?
                            <>
                              <div className={`${cascading.secrow} mt-3`}>
                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{data.tokenA.symbol} Swap Rate

                                </p>
                                <p className={`mb-0 ${cascading.labels}`}>1 {data.tokenA.symbol} <FaArrowRight /> {roundToSignificant(data.SwapeRate0, 6)} {data.tokenB.symbol}
                                </p>
                              </div>

                              <div className={`${cascading.secrow} mt-3`}>
                                <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{data.tokenB.symbol} Swap Rate

                                </p>
                                <p className={`mb-0 ${cascading.labels}`}>1 {data.tokenB.symbol} <FaArrowRight /> {roundToSignificant(data.SwapeRate1, 6)} {data.tokenA.symbol}
                                </p>
                              </div>
                            </> : ""}

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{data.tokenA.symbol}/{data.tokenB.symbol} liquidity ratio

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(data.toTokenper, 6)}
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>{data.tokenB.symbol}/{data.tokenA.symbol} liquidity ratio

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>{roundToSignificant(data.fromTokenper, 6)}
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>Pool Share

                            </p>
                            <p className={`mb-0 ${cascading.labels}`}>{data.poolShare} %
                            </p>
                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>LP Address

                            </p>
                            <div className='d-flex align-items-center justify-content-between'>
                              <p className={`mb-0 ${cascading.labels}`}>
                                {`${(data.lpAddress).substring(0, 5)}...${data.lpAddress.substring(38, 42)}`}
                              </p>

                              <CopyToClipboard
                                text={"4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB"}
                                onCopy={() =>
                                  toast('Address copied', {
                                    duration: 2000,
                                    position: 'top-right',

                                    // Styling
                                    style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                                    className: '',

                                    // Custom Icon

                                    // Change colors of success/error/loading icon
                                    iconTheme: {
                                      primary: '#000',
                                      secondary: '#fff',
                                    },

                                    // Aria
                                    ariaProps: {
                                      role: 'status',
                                      'aria-live': 'polite',
                                    },
                                  })
                                }

                              >
                                <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                              </CopyToClipboard>
                            </div>


                          </div>

                          <div className={`${cascading.secrow} mt-3`}>
                            <p className={`mb-0 d-flex align-items-center ${cascading.label}`}>NFT Pool Address

                            </p>
                            {data.poolAddress !== ZEROTH_ADDRESS ?
                              <div className='d-flex align-items-center justify-content-between'>
                                <p className={`mb-0 ${cascading.labels}`}>
                                  {`${(data.poolAddress).substring(0, 5)}...${data.poolAddress.substring(38, 42)}`}
                                </p>

                                <CopyToClipboard
                                  text={"4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB"}
                                  onCopy={() =>
                                    toast('Address copied', {
                                      duration: 2000,
                                      position: 'top-right',

                                      // Styling
                                      style: { background: "#1c2029", color: "#fff", minWidth: 200 },
                                      className: '',

                                      // Custom Icon

                                      // Change colors of success/error/loading icon
                                      iconTheme: {
                                        primary: '#000',
                                        secondary: '#fff',
                                      },

                                      // Aria
                                      ariaProps: {
                                        role: 'status',
                                        'aria-live': 'polite',
                                      },
                                    })
                                  }

                                >
                                  <button className={`mb-0 ${cascading.copybtntrans}`}><FaCopy /></button>
                                </CopyToClipboard>
                              </div> : ""}


                          </div>


                        </div>

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>


              <p className={`${cascading.innerheadwhitetitle}`}>Boost Options
              </p>



              <div className='row mt-4'>
                <div className='col-12 col-md-5'>
                  <p className={`mb-0 ${cascading.innerheadwhite}`}>Lock duration
                  </p>
                  <a href="javascript:void(0)" className={`${cascading.themelink}`} onClick={() => { maxLockDuration() }}>Set Max</a>
                </div>
                <div className='col-12 col-md-7 mt-3 mt-md-0'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={DecDisable} onClick={() => { DeCreaseDuration() }}>
                      <FaMinus />
                    </button>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Months</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{months}</p>


                    </div>
                    <div>
                      <p className={`mb-1 text-center ${cascading.textgreydesc}`}>Days</p>
                      <p className={`mb-0 text-center ${cascading.textwhitedesc}`} >{days}</p>


                    </div>
                    <button className={`btn ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={incDisable} onClick={() => { inCreaseDuration() }}>
                      <FaPlus />
                    </button>
                  </div>
                  <p className={`mb-1 text-end mt-3 ${cascading.textgreydesc}`}>{lockPerc}% lock bonus (x{multiper})</p>
                </div>
              </div>




              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Estimates
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Deposit value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm} ${cascading.innerheadthemesmbreak}`}>${isEmpty(poolInfo?.lpPriceInusd) ? 0 :roundToSignificant(parseFloat(data.addliquidity) * parseFloat(poolInfo.lpPriceInusd),6)}</p>
                </div>
              </div>



              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(poolInfo?.SwapFeeApr,4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(poolInfo?.FramBaseApr,6)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(lockBonusApr,6)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm} ${cascading.innerheadthemesmbreak}`}>{roundToSignificant(totalApr,4)}%</p>
                </div>
              </div>
            </div>

            <div className='mt-4'>
              <button className={`btn ${cascading.connectwalletbtn}`} type='button' onClick={() => { SupplyAndCreatePosition(); }}>Create Position</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>
            </div>
            {/* <div className='p-3 text-center'>
<button className={`btn ${cascading.connectwalletbtn}`} type='button'>Close</button>
</div> */}
          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

