import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/dbrequirestyle.module.css';


import { IoMdClose } from "react-icons/io";

export default function DbRequiremodal(props) {

// const [dbrequire, setDbrequire] = useState(true);



  return (

    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`}>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>DB required</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>

          {/* <FaArrowUp  fill='#fff' fontSize={40}/> */}
          <p className={`${cascading.greentext}`}>Insufficient DB balance</p>
          <p className={`${cascading.whitetext} mb-1`}>You'll need DB to stake this pool!</p>
          <p className={`${cascading.whitetext}`}>Buy some DB, or make sure your DB isn't in another pool or LP.</p>
          <div className='text-center py-4'>
          <button className={`btn ${cascading.connectwalletbtn}`} type='button'>Buy DB</button>

          </div>
          {/* <Link to={`${CHAINS.TRANSACTION}/${localStorage.getItem("addLiquidity")}`} className={`${cascading.declabel}`}>View on BscScan</Link> */}
          <button className={`${cascading.declabel}`} onClick={()=>{
          // window.open(`${CHAINS[GetChainIndex()].TRANSACTION}/${JSON.parse(localStorage.getItem("removeLiquidity")).txhash}`,'_blank');
           
            }} target="_blank">Locate Assets</button>




        </Modal.Body>

      </Modal>
    </div>
  )
}

