import React, { useState,useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/positionmodalstyle.module.css';
import toast, { Toaster } from 'react-hot-toast';
import Accordion from 'react-bootstrap/Accordion';
import CopyToClipboard from "react-copy-to-clipboard";
import { FaArrowRight, FaChevronLeft, FaCopy, FaAngleUp, FaExternalLinkAlt, FaRegQuestionCircle, FaRegTimesCircle, FaDownload, FaUpload, FaLock, FaRocket, FaCoins, FaCheckCircle } from "react-icons/fa";


import { FaArrowDown } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import bnblogo from '../assests/images/bnb.png';
import coinlogo from '../assests/images/logomini.png';


import { useSelector } from 'react-redux';


import { IoMdClose } from "react-icons/io";
import { roundToSignificant } from '../hooks/useCommon';
import { isEmpty } from '../lib/isEmpty';

import { GetBounsApr } from '../hooks/useNFTpool';

import Countdown from 'react-countdown';
export default function Positionmodal(props) {

  const [positionmodal, setPositionmodal] = useState(true);
  const [isyielded, setIsyielded] = useState(true);
  const [islocked, setIslocked] = useState(true);
  const [isboosted, setIsboosted] = useState(false);
  const [poolInfo, setPoolInfo] = useState({})
  const [BonusApr, setBonusApr] = useState('0')
  const [totalApr, settotalApr] = useState('0')
  const { positionrecord } = useSelector((state) => (state.position))
  const { nftpooldata } = useSelector((state) => (state.nftpool))
  useEffect(() => {
    let poolInfo = nftpooldata.find((val) => (val?.poolAddress?.toLowerCase() == positionrecord?.poolAddress?.toLowerCase()))
    setPoolInfo(poolInfo)
    let isyielded = parseFloat(poolInfo.allocPoint) > 0 ? true : false
    let islocked = parseFloat(positionrecord.lockDuration) > 0 ? true : false
    let isboosted = parseFloat(positionrecord.boostPoints) > 0 ? true : false
    setIsyielded(isyielded)
    setIsboosted(isboosted)
    setIslocked(islocked)
  }, [positionrecord, nftpooldata])
  useEffect(()=>{
    CalculateBonusApr()
  },[positionrecord,poolInfo])

  const CalculateBonusApr = () => {
    try {
      if (!isEmpty(poolInfo) && !isEmpty(positionrecord)) {
        let bonusPercentage = parseFloat(positionrecord?.lockMultiplier) * 100 + parseFloat(positionrecord?.boostMultiplier) * 100
        let BonusApr = GetBounsApr({
          annualInterestRate: poolInfo.FramBaseApr,
          bonusPercentage: bonusPercentage
        })
        BonusApr = isNaN(BonusApr) ? 0 : BonusApr
        setBonusApr(BonusApr)
        let totalApr = parseFloat(BonusApr) + parseFloat(poolInfo.FramBaseApr) + parseFloat(poolInfo.SwapFeeApr)
        settotalApr(totalApr)
      }

    } catch (err) {
      console.log('CalculateBonusApr__err')
    }
  }

  const Completionist = () => <span className={`${cascading.themetime}`}>00D 00h 00m 00s</span>;

  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

  const CalculateEndTime = () => {
    try {
      let endTime = parseFloat(positionrecord.startLockTime) + parseFloat(positionrecord.lockDuration)
      console.log(endTime, 'CalculateEndTime', new Date(endTime * 1000))
      return new Date(endTime * 1000)
    } catch (err) {
      console.log(err, 'CalculateEndTime__err')
    }
  }

  return (
    <>
      <div className={`${cascading.networkmodalstyle}`}>
        <Modal centered show={positionmodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
          <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
            <h4 className={`${cascading.modaltitle}`}>Position</h4>
            <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

          </Modal.Header>
          <Modal.Body className={`pt-0 ${cascading.modalclr} ${cascading.modalbody}`}>

            <div>

              <div className={`${cascading.imgsec}`}>
                <div className={`${cascading.leftimgsec}`}>
                  <img src={bnblogo} alt='img' className={`img-fluid ${cascading.fromimg}`} />
                  <img src={coinlogo} alt='img' className={`img-fluid  ${cascading.toimg}`} />
                </div>
                <div>
                  <p className={`${cascading.coinname} mb-0`}>{positionrecord.lpsymbol}</p>
                  <p className={`${cascading.coinnamegrey} mb-0`}>#ID-{positionrecord.tokenId}</p>
                </div>


              </div>


              <p className={`${cascading.tokenvalue}`}>$1.03 - <span>20.03%</span> APR</p>
              {/* <p className={`${cascading.tokenvaluedesc}`}>This position has<span>$<FaChevronLeft />0.001 %</span> pending farming rewards</p> */}

              <p className={`${cascading.tokenvaluedesc}`}>This position has<span>{roundToSignificant(positionrecord.dspRewards, 6)} DSP</span> pending farming rewards</p>


              <div className='d-flex align-items-start justify-content-center mt-4'>
                <div className='text-center px-2'>
                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} onClick={() => { props.addmodal(); props.onHide(); }}>
                    <FaDownload />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Add</p>
                </div>

                <div className='text-center px-2'>
                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} disabled={!positionrecord.isWithdraw} onClick={() => { props.withdrawmodal(); props.onHide(); }}>
                    <FaUpload />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Withdraw</p>

                </div>
                <div className='text-center px-2'>

                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} onClick={() => { props.lockmodal(); props.onHide(); }}>
                    <FaLock />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Lock</p>

                </div>
                <div className='text-center px-2'>

                  <button className={`btn mx-2 ${cascading.themelink} ${cascading.buttonstyle_ul}`} onClick={() => { props.boostmodal(); props.onHide(); }}>
                    <FaRocket />
                  </button>
                  <p className={`${cascading.tokenvaluedesc} mt-1`}>Yield Boost</p>

                </div>
              </div>



              <p className={`${cascading.innerheadwhitetitle} mt-2`}>Properties
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>
                    <div className='d-flex align-items-center'>
                      {
                        isyielded ?
                          <FaCheckCircle className={`${cascading.themeicon}`} />
                          :
                          <FaRegTimesCircle className={`${cascading.invisibleicon}`} />

                      }
                      {
                        isyielded ?
                          "Yield-bearing"
                          :
                          "Unyielded"
                      }
                    </div>
                  </p>
                </div>
                <div className='col-4 col-md-4'>
                  {
                    isyielded ?
                      <>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>{roundToSignificant(poolInfo?.FramBaseApr, 6)}%</p>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>Farm APR</p>
                      </>
                      :
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>-</p>

                  }

                </div>
              </div>
              <hr className={`${cascading.hrstyle}`} />

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>
                    <div className='d-flex align-items-center'>
                      {
                        islocked ?
                          <FaCheckCircle className={`${cascading.themeicon}`} />
                          :
                          <FaRegTimesCircle className={`${cascading.invisibleicon}`} />

                      }
                      {
                        islocked ?
                          <div className='d-flex flex-column'>

                            <p className={`mb-0 text-start ${cascading.innerheadthemesm}`}>Locked</p>
                            <p className={`mb-0 text-start ${cascading.innerheadwhitesm}`}>{positionrecord?.lockMultiplier}x multiplier</p>
                          </div>

                          :
                          "Unlocked"
                      }
                    </div>
                  </p>
                </div>
                <div className='col-4 col-md-4'>
                  {
                    islocked ?
                      <>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>
                          <Countdown
                            date={CalculateEndTime()}
                            renderer={({ days, hours, minutes, seconds, completed }) => {
                              console.log(days, hours, minutes, seconds, completed, 'renderer');
                              if (completed) {
                                return Completionist();

                              } else {
                                // Render a countdown
                                return <span className={`${cascading.themetime}`}>{formatTime(days)}D {formatTime(hours)}h {formatTime(minutes)}m {formatTime(seconds)}s</span>;
                              }
                            }}
                          />

                        </p>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>Remaining time</p>
                      </>
                      :
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>-</p>

                  }

                </div>
              </div>
              <hr className={`${cascading.hrstyle}`} />


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>
                    <div className='d-flex align-items-center'>
                      {
                        isboosted ?
                          <FaCheckCircle className={`${cascading.themeicon}`} />
                          :
                          <FaRegTimesCircle className={`${cascading.invisibleicon}`} />

                      }
                      {
                        isboosted ?
                          <div className='d-flex flex-column'>

                            <p className={`mb-0 text-start ${cascading.innerheadthemesm}`}>Boosted</p>
                            <p className={`mb-0 text-start ${cascading.innerheadwhitesm}`}>{positionrecord?.boostMultiplier}x multiplier</p>

                          </div>
                          :
                          "Unboosted"
                      }
                    </div>
                  </p>

                </div>
                <div className='col-4 col-md-4'>
                  {
                    isyielded ?
                      <>
                        <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>{positionrecord?.boostPoints} PZLX</p>
                        <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>Boost allocation</p>
                      </>
                      :
                      <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>-</p>

                  }
                </div>
              </div>

              <p className={`${cascading.innerheadwhitetitle} mt-4`}>Data Breakdown
              </p>


              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Value</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>{poolInfo.notinusd ? '':'$'}{isEmpty(poolInfo?.lpPriceInusd) ? 0 : roundToSignificant(parseFloat(positionrecord.amount) * parseFloat(poolInfo.lpPriceInusd),6)}</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Swap fees APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>{roundToSignificant(poolInfo?.SwapFeeApr, 4)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Farm base APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>{roundToSignificant(poolInfo?.FramBaseApr, 6)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Lock bonus APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadwhitesm}`}>{roundToSignificant(BonusApr, 6)}%</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-8 col-md-8'>
                  <p className={`mb-0 ${cascading.innerheadwhitesm}`}>Total APR</p>
                </div>
                <div className='col-4 col-md-4'>
                  <p className={`mb-0 text-end ${cascading.innerheadthemesm}`}>{roundToSignificant(totalApr, 4)}%</p>
                </div>
              </div>
            </div>

            <div className='mt-4'>


              <button className={`btn ${cascading.connectwalletbtn}`} onClick={() => { props.harvestmodal(); props.onHide() }} type='button'>Harvest</button>
              <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Close</button>

            </div>

          </Modal.Body>

        </Modal>
      </div>
    </>
  )
}

