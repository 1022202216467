import React, { useState,useEffect } from 'react';
import cascading from '../../assests/css/xgrailpools.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../../common/footer'
import Header from '../../common/header';
import { useNavigate } from 'react-router-dom';
import { FaThList } from "react-icons/fa";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import Switch from "react-switch";
import { Dropdown } from 'react-bootstrap';
import Farmcard from '../../components/farmcard';
import bnblogo from '../../assests/images/bnb.png';
import coinlogo from '../../assests/images/logomini.png';

import home_icon from '../../assests/images/home_icon.png';
import pools_icon from '../../assests/images/pools_icon.png';


import logohome from '../../assests/images/coinicon.png';

import Yumpoollist from '../../components/yumpoollist';
import { roundToSignificant } from '../../hooks/useCommon';


import { useSelector } from 'react-redux'
import { isEmpty } from '../../lib/isEmpty';

const filterOption = {
    'Incentives_only': 'Incentives only',
    'Staked_only': 'Staked only'
}
export default function Xgrailpools() {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [listtype, setListtype] = useState(false);
    const [status, setStatus] = useState(true);
    const [filter, setfilter] = useState('');
    const [loaded, setisloaded] = useState(false);

    const { totalTvl, ispoolload ,nftpooldata} = useSelector((state) => (state.nftpool))

    useEffect(() => {
        if (isEmpty(nftpooldata)) {
            setloader()
        } else if (!isEmpty(nftpooldata)) {
            Hideloader()
        }
    }, [ispoolload])

    const setloader = () => {
        try {
            setisloaded(true)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }
    const Hideloader = () => {
        try {
            setisloaded(false)
        } catch (err) {
            console.log(err, 'setloader__Err')
        }
    }
    return (
        <>
            <div className={loaded ? 'loader_div' : 'loader_div d-none'} id="loader_div">
                <div className="circle">
                </div>
                <div className="circle_img">
                    <img src={logohome} className='img-fluid' />
                </div>
            </div>
            <Header />
            <div className={loaded ? 'logo_overlay' : 'logo_overlay_none'}>
                <div className={`${cascading.innerpages} ${cascading.earnpage}`}>
                    <div className={`container`}>
                        <div className={`row ${cascading.headrow}`}>
                            <p className={`${cascading.pagehead}`}>Pools</p>
                            <p className={`${cascading.pagedesc}`}>Create positions into pools to earn swap fees and PZLX.</p>
                        </div>
                        <div className='row'>
                            <div className={`col-lg-3 mb-4`} >

                                <div className={`${cascading.balancecard}`}>
                                    <div>
                                        <p className={`${cascading.labelname}`}>Total TVL</p>
                                        <p className={`${cascading.labelvalue}`}>$ {roundToSignificant(totalTvl, 6)}</p>
                                    </div>
                                    <div>
                                        <img className={`img-fluid ${cascading.balanceimg}`} src={home_icon} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className={`col-lg-3 mb-4`} >
                                {/* <div className={`${cascading.balancecard}`}>
                                <div>
                                    <p className={`${cascading.labelname}`}>Pools TVL</p>
                                    <p className={`${cascading.labelvalue}`}>$32.32 M</p>
                                </div>
                                <div>
                                    <img className={`img-fluid ${cascading.balanceimg}`} src={pools_icon} alt='img' />
                                </div>
                            </div> */}
                            </div>
                        </div>





                        <div className={`row ${cascading.filterrow}`}>
                            <div className={`col-lg-6 mb-3 mb-lg-0`}>
                                <div className={`${cascading.leftfiltersec}`}>
                                </div>

                            </div>
                            <div className={`col-lg-12 mb-3`}>
                                <div className={`${cascading.rightfiltersec}`}>
                                    <div>
                                        <p className={`${cascading.labelname} mb-2`}>Filters</p>
                                        <Dropdown className={`${cascading.dropdownstyle}`} onSelect={(e) => { setfilter(e) }}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {isEmpty(filter) ? 'Select Filter' : filterOption[`${filter}`]}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className={`${cascading.dropdownmenustyle}`}>
                                                <Dropdown.Item href="#/action-2" eventKey={''}>No filter</Dropdown.Item>
                                                <Dropdown.Item href="#/action-1" eventKey={'Incentives_only'}>Incentives only</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2" eventKey={'Staked_only'}>Staked only</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    {/* <div>
                                    <p className={`${cascading.labelname} mb-2`}>Search</p>
                                    <input type="text" className={`form-control ${cascading.inputstyle}`} placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />


                                </div> */}

                                </div>
                            </div>

                        </div>

                        <div className={`${cascading.farmcontentsec} mt-4`}>


                            {status ?
                                <>
                                    <div className={`row`}>
                                        <Yumpoollist
                                            filter={filter}
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <p className={`${cascading.emptylabels}`}>These pools are no longer distributing rewards. Please unstake your tokens.</p>


                                </>
                            }


                        </div>
                    </div>


                </div>
            </div>
            <Footer />
        </>
    )
}
